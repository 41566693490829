import $api, { GOODS_COMPARE_URL } from "../../../http"

const getTasks = async (shopDbName) => {
    if(shopDbName==='') return [];
    const response = await $api.get(GOODS_COMPARE_URL + '/tasks-of-revaluation?shopDbName='+shopDbName);
    return response.data.map(x=>({...x, key: x.taskId, count: '', selected: false}));
}

const saveRevaluations = async (items) => {
    const data = items.filter(x=>x.count!=='').map(x=>{
        if(x.count==='') return {...x, count: 0};
        if(!isNaN(parseFloat(x.count.replace(',','.'))))
            return {...x, count: parseFloat(x.count.replace(',','.'))};
        else
            throw new Error('Не удалось переобразовать в число');
    });
    await $api.post(GOODS_COMPARE_URL + '/revaluation', {items: data});
}

export {getTasks, saveRevaluations};