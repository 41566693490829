import { Input, Modal } from "antd";
import { useRef, useState } from "react";
import $api from "../../../../http";

const changeQuantityFact = async (shopDbName, inventoryId, goodId, quantityStr) => {
    const quantity = parseFloat(quantityStr);
    if(isNaN(quantity)) throw new Error('Значение не является числом');
    const resp = await $api.put(`/shops/inventory/${inventoryId}/summary`,{
        inventoryId,
        goodId,
        quantity,
    }, { headers: {shopDbName}});
    return resp.data;
}

export default function useChangeQuantityFactModal(shopDbName, inventoryId, setSummary){
    const [open, setOpen] = useState(false);
    const inputRef = useRef();
    const goodIdRef = useRef(0);
    
    const openModal = (goodId) => {
        goodIdRef.current = goodId;
        inputRef.current.input.focus();
        setOpen(true);
    };

    const okHandler = () =>{
        const value = inputRef.current.input.value;
        changeQuantityFact(shopDbName, inventoryId, goodIdRef.current, value)
            .then(x=>{
                setSummary(prev=>({...prev, amountFact: x.inventoryAmountFact, positions: prev.positions.map(p=>{
                    if(p.goodId===goodIdRef.current)
                        return {...p, quantityFact: x.positionFact};
                    return p;
                })}));
                setOpen(false);
            })
            .catch(console.error);
    }

    const dialog = <Modal onOk={_=>okHandler()} onCancel={_=>setOpen(false)} open={open} title='Введите количество'>
        <Input ref={inputRef}/>
    </Modal>;

    return [openModal, dialog];
}