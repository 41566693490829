import { Button, DatePicker, Select, Space, Table } from "antd";
import { arrivalListTableColumns } from "./constants";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getArrivals, downloadNotProcessedPhoto, getShops } from "./apis";
import { useNavigate } from "react-router-dom";
import ArrivalProcessingPhoto from "./ArrivalProcessingPhoto";
import ArrivalNotProcessedTable from "./ArrivalNotProcessedTable";
import UploadEgaisModal from "../arrivals-processing/UploadEgaisModal";
import { useShopDbNameStore } from "./useShopDbNameStore";

const { RangePicker } = DatePicker;

function ArrivalListPage(){
    const navigate = useNavigate();
    const shopDbName = useShopDbNameStore(state=>state.shopDbName);
    const setShopDbName = useShopDbNameStore(state=>state.setShopDbName);
    const [shops, setShops] = useState([{ label: '', value: '' }]);
    const [loading, setLoading] = useState(false);
    const [arrivals, setArrivals] = useState([]);
    const [filters, setFilters] = useState({ start: dayjs().add(-15, 'day'), stop: dayjs(), supplierName: '', shopDbName: '' });

    useEffect(() => {
        getShops().then(setShops).catch(console.error);
    }, []);

    useEffect(()=>{
        setLoading(true);
        getArrivals({...filters}).then(x=>{
            setLoading(false);
            setArrivals(x);
        }).catch(e=>{
            console.error(e);
            setLoading(false);
            setArrivals([]);
        });
    }, [filters]);

    return <div>
        <Space>
            <label>Магазин</label>
            <Select options={shops} onChange={setShopDbName} defaultValue={shopDbName} style={{width: '150px'}}/>
            <label>Поставщик</label>
            <Select />
            <label>Дата</label>
            <RangePicker defaultValue={[filters.start, filters.stop]} onChange={([start, stop])=>setFilters({...filters, start, stop})}/>
            <ArrivalProcessingPhoto />
            <UploadEgaisModal shopDbName={shopDbName}/>
        </Space>
        <ArrivalNotProcessedTable shopDbName={shopDbName}/>

        <Table 
            loading={loading} 
            dataSource={arrivals} 
            columns={arrivalListTableColumns} 
            rowKey={item=>item.shopDbName+"_"+item.id} 
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => { navigate("/shops/arrival-list/"+ record.id, {state: {shopDbName: record.shopDbName}})}, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            pagination={false} size="small"
            />
    </div>
}

export default ArrivalListPage;