import dayjs from "dayjs";
import { $apiKegaCounterControll } from "../../http"

const getShops = async () => {
    const resp = await $apiKegaCounterControll.get("/shops");
    const result = resp.data.map(s=>({label: s.name, value: `${s.id}`}));
    result.push({label: "Все", value: ''});
    return result;
}

const getSuppliers = async () => {
    const resp = await $apiKegaCounterControll.get("/suppliers");
    const result = resp.data.map(s=>({label: s.name, value: s.id}));
    result.push({label: "Все", value: ''});
    return result;
}

const getkegaOwners = async () => {
    const resp = await $apiKegaCounterControll.get("/kega-owners");
    const result = resp.data.map(s=>({label: s.name, value: s.id}));
    result.push({label: "Все", value: ''});
    return result;
}

const getKegTypes = async () => {
    const resp = await $apiKegaCounterControll.get("/kegs");
    const result = resp.data.map(s=>({label: s.name, value: s.id}));
    result.push({label: "", value: 0});
    return result;
}

const documentValidation = ({shopId, supplierId, kegaOwnerId, positions}) => {
    if(shopId===0 || supplierId===0 || kegaOwnerId===0) return false;
    positions.forEach(p=>{
        if(p.countKega==='') return false;
    });
    return true;
}

const save = async ({id, create, shopId, supplierId, kegaOwnerId, driveType, positions, images}) => {
    if(shopId=='') throw new Error('Не выбран магазин');
    if(supplierId=='') throw new Error('Не выбран поставщик');
    if(kegaOwnerId=='') throw new Error('Не выбран владелец кеги');
    if(positions.length==0) throw new Error('Нет ни одной строки');
    positions.forEach(p=>{
        if(p.countKega==='') throw new Error('Не указано количество кег');
    });

    const request = {id, dateAt: create.format('YYYY-MM-DD'), shopId, supplierId, kegaOwnerId, driveType,
        positions: positions.map(p=> ({id: p.id, kegaTypeId: p.kegaTypeId, countKega: parseInt(p.countKega)})),
        images
    }
    let response = null;
    if(id===0)
        response = await $apiKegaCounterControll.post('/documents', request);
    else
        response = await $apiKegaCounterControll.put('/documents', request);
    const document = response.data;
    document.create = dayjs(document.dateAt);
    document.positions.forEach((p, i)=>{
        p.numPP=i+1;
    })
    return document;
}

const documentDelete = async (id) => await $apiKegaCounterControll.delete('/documents/'+id);

const getDocumentList = async (dateWith, dateBy, shopId, kegaOwnerId) => {
    const _with = dateWith.format('YYYY-MM-DD');
    const _by = dateBy.format('YYYY-MM-DD');
    const _shopId  = shopId==='' ? '' : '&shopId=' + shopId;
    const _kegaOwnerId = kegaOwnerId==='' ? '' : '&kegaOwnerId=' + kegaOwnerId;
    const result = await $apiKegaCounterControll.get(`/documents?with=${_with}&by=${_by}` + _shopId + _kegaOwnerId);
    return result.data;
}

const loadingDocument = async (id) => {
    const response = await $apiKegaCounterControll.get('/documents/'+id);
    const document = response.data;
    document.create = dayjs(document.dateAt);
    document.positions.forEach((p, i)=>{
        p.numPP=i+1;
    })
    return document;
}

const getBalance = async (shopId) => {
    const response = await $apiKegaCounterControll.get('/balance'+(shopId==='' ? '' : '?shopId='+shopId));
    return response.data;
}

const uploadPhoto = async (imageSrc) => {
    const result = await $apiKegaCounterControll.post('/upload', {uuid: uuidv4(), image: imageSrc});
    return result.data;
}

export {getShops, getSuppliers, getKegTypes, getkegaOwners, documentValidation, save, documentDelete, getDocumentList, loadingDocument, getBalance, uploadPhoto};

function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }