import { PhoneOutlined, PictureOutlined } from "@ant-design/icons";
import { Button } from "antd";

const arrivalListTableColumns = [
    {
        title: 'Магазин',
        dataIndex: 'shopName',
        key: 'shopName',
    },
    {
        title: 'Дата',
        dataIndex: 'dateArrivalStr',
        key: 'dateArrivalStr',
    },
    {
        title: 'Номер',
        dataIndex: 'num',
        key: 'num',
    },
    {
        title: 'Поставщик',
        dataIndex: 'supplierName',
        key: 'supplierName',
    },
    {
        title: 'Закупка',
        dataIndex: 'sumArrival',
        key: 'sumArrival',
    },
    {
        title: 'Продажа',
        dataIndex: 'sumSale',
        key: 'sumSale',
    },
    {
        title: 'Оплачен',
        dataIndex: 'isPayment',
        key: 'isPayment',
    },
    {
        title: '',
        dataIndex: 'c',
        render: (_, record) => <>
            <Button size="small"><PictureOutlined /></Button>
            </>
    },
]

const arrivalTablePositionColumns = [
    {
        title: 'Товар',
        dataIndex: 'goodName',
        key: 'goodName',
    },
    {
        title: 'Кол-во',
        dataIndex: 'count',
        key: 'count',
    },
    {
        title: 'Цена закупки',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Цена продажи',
        dataIndex: 'priceSell',
        key: 'priceSell',
    },
    {
        title: 'Наценка',
        dataIndex: 'percent',
        render: (_, item) => Math.floor(item.price / item.priceSell * 100 ) + "%"
    },
    {
        title: 'Сумма закупки',
        dataIndex: 'percent',
        render: (_, item) => Math.floor(item.price * item.count )
    },
    {
        title: 'Сумма продажи',
        dataIndex: 'percent',
        render: (_, item) => Math.floor(item.priceSell * item.count )
    },
]

export { arrivalListTableColumns, arrivalTablePositionColumns }