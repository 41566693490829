import { $apiPriceCompare } from "../../http"


const getShops = async () =>{
    try{
        const resp = await $apiPriceCompare.get("/shops");
        const shops = resp.data.map(s=>({label: s.name, value: s.dbName}));
        shops.push({label: '', value: ''});
        return shops;
    }
    catch(e){
        console.error(e);
    }
    return [{label: '', value: ''}];
}

const getGroups = async (dbName) => {
    if(dbName==='') return [{label: '', value: ''}];
    try{
        const resp = await $apiPriceCompare.get("/good-groups?dbName="+dbName);
        const groups = resp.data.map(gr=>({label: gr.name, value: '' + gr.id}));
        groups.push({label: '', value: ''});
        return groups;
    }
    catch(e){
        console.error(e);
    }
    return [{label: '', value: ''}];
}

const autoCompare = async (dbName) => {
    if(dbName==='') throw new Error('Не выбран магазин');
    const resp = await $apiPriceCompare.get('/compare-barcodes?dbName='+dbName);
    return resp.data;
}

const getGoods = async (dbName, goodGroupId) => {
    const resp = await $apiPriceCompare.get('/goods/no-compare');
    return resp.data;
}

export {getShops, getGroups, autoCompare};