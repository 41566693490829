import { Button, List, Select, Space, Input, message, Checkbox } from "antd";
import { BarcodeOutlined, EditOutlined, DeleteOutlined, LinkOutlined, IeOutlined, DollarOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import useShops from "./hooks/useShops";
import useLegacyGroups from "./hooks/useLegacyGroups";
import useLegacyGoods from "./hooks/useLegacyGoods";
import GroupsCompareModal from "./GroupsCompareModal";
import useGetCompareGoods from "./hooks/useGetCompareGoods";
import $api, { GOODS_COMPARE_URL } from "../../../http";
import dayjs from "dayjs";
import EditGoodModal from "./modals/EditGoodModal";
import useDebounce from "../../../hooks/useDebounce";
import SearchComparedComponent from "./components/SearchComparedComponent";
import useGetNamesByBarcodes from "./hooks/getNameByBarcodes";
import MoreInformationDialog from "../../reports/price-no-compare/components/MoreInformationDialog";
const { Search } = Input;

const getLegacyActionsButtons = (item, findGoodByComparedBarcode, compareSelectionGood, addNewCompareGoods, removeGoodShop, openGetNamesModal, deleteGood) => {
    if(item.isAdded) return [
        <Button
            onClick={_=>removeGoodShop(item)}
            size="small"
            title="Удалить сопоставление"
            ><LinkOutlined />
        </Button>
    ];
    if(item.barcodes.length===0)
        return [
            <Button 
                onClick={()=>{ compareSelectionGood(item) }}
                size="small"
                title="Сопоставить"
                >=</Button>, 
            <Button 
                onClick={()=>{ addNewCompareGoods(item) }}
                size="small"
                title="Добавить новую позицию"
                >+</Button>,
            <Button
                onClick={()=>{ deleteGood(item) }}
                size="small"
                title="Удалить в магазине"
                ><DeleteOutlined /></Button>
        ];
    return [
        <Button
            onClick={_=>openGetNamesModal(item)}
            size="small"
            title="Поиск название в глобальном справочнике"
            >
            <IeOutlined />
        </Button>,
        <Button
            onClick={_=>findGoodByComparedBarcode(item)}
            size="small"
            title="Найти сопоставление по штрих-коду"
            ><BarcodeOutlined />
        </Button>,
        <Button 
            onClick={()=>{ compareSelectionGood(item) }}
            size="small"
            title="Сопоставить"
            >=</Button>, 
        <Button 
            onClick={()=>{ addNewCompareGoods(item) }}
            size="small"
            title="Добавить новую позицию"
            >+</Button>,
        <Button
            onClick={()=>{ deleteGood(item) }}
            size="small"
            title="Удалить в магазине"
            ><DeleteOutlined /></Button>
    ];
}

const Context = React.createContext({
    name: 'Default',
  });

function TabBeerCompare(){
    const [shopDbNameSelect, setShopDbNameSelect] = useState('');
    const [legacyGroupsSelect, setLegacyGroupsSelect] = useState(0);
    const [compareGroupId, setCompareGroupId] = useState(0);
    const shops = useShops();
    const [searchValue, setSearchValue] = useState('');
    const [comparedGoodsFilter, setComapredGoodsFilter] = useState(false);
    const [openInfoDialog, infoDialogComaponent] = MoreInformationDialog();

    const legacyGroups = useLegacyGroups(shopDbNameSelect);
    const [legacyGoods, getLegacyGoods, compareAllGoods] = useLegacyGoods(shopDbNameSelect, legacyGroupsSelect, comparedGoodsFilter);
    const [compareGoods, getCompareGoods, onSelectedCompareGood] = useGetCompareGoods(compareGroupId, searchValue);
    const [api, contextHolder] = message.useMessage();

    const [editGood, setEditGood] = useState(null);
    const [getNamesModal, openGetNamesModal] = useGetNamesByBarcodes();

    const addNewCompareGoods = async ({uuid, name, specialType, unit, price, barcodes}) => {
        if(!window.confirm(`Создать новый товар: ${name}?`)) return;
        if(compareGroupId===undefined || compareGroupId===0) return;
        try{
            await $api.post(GOODS_COMPARE_URL + '/goods', {
                shopDbName: shopDbNameSelect,
                goodGroupId: compareGroupId,
                uuid,
                name,
                specialType,
                unit,
                price,
                barcodes
            });
            getCompareGoods();
            getLegacyGoods();
        }
        catch(e){
            console.error(e);
        }
    }

    const compareSelectionGood = async ({uuid, name, price, barcodes}) => {
        const good = compareGoods.find(g=>g.selected);
        if(good===undefined) return;
        if(window.confirm(`Сопоставить ${name} с ${good.name}`))
        try{
            await $api.post(GOODS_COMPARE_URL + '/goods-shop', {
                goodId: good.id,
                shopDbName: shopDbNameSelect,
                uuid,
                price,
                barcodes
            });
            await getLegacyGoods();
        }
        catch(e){
            console.error(e);
        }
    }

    const findGoodByComparedBarcode = ({barcodes}) =>{
        let findGood = null;
        barcodes.forEach(code=>{
            let item = compareGoods.find(item => item.barcodes.indexOf(code)>-1);
            if(item===undefined) return;
            findGood=item;
        });
        api.open({
            type: findGood===null ? 'error' : 'success',
            content: findGood===null ? 'Не найден' : 'Найден',
          });
        onSelectedCompareGood(findGood);
    }

    const changeShop = (shopDbName) => {
        setShopDbNameSelect(shopDbName);
        setLegacyGroupsSelect(0);
    }
    
    const editGoodCompliteHandler = () => {
        setEditGood(null);
        getCompareGoods();
    }
    const deleteCompareGood = async (id) => {
        if(window.confirm("Удалить?"))
            try{
                await $api.delete(GOODS_COMPARE_URL + '/goods/'+id);
                getCompareGoods();
            }
            catch(e){
                console.error(e);
            }
    }

    const deleteLegacyGood = async ({uuid}) =>{
        if(window.confirm("Удалить?")) 
            try{
                await $api.delete(GOODS_COMPARE_URL + `/legacy-goods?shopDbName=${shopDbNameSelect}&uuid=${uuid}`);
                getLegacyGoods();
            }
            catch(e){
                console.error(e);
            }
    }

    const removeGoodShop = async ({uuid}) => {
        if(window.confirm("Удалить сопоставление?"))
            try{
                await $api.delete(GOODS_COMPARE_URL + `/goods-shop?shopDbName=${shopDbNameSelect}&uuid=${uuid}`);
                getLegacyGoods();
            }
            catch(e){
                console.error(e);
            }
    }
    
    return <Context.Provider>
        {contextHolder}
        {getNamesModal}
        {infoDialogComaponent}
        <div style={{display: 'grid', gridTemplateColumns:'1fr 1fr'}}>
            {editGood!==null && <EditGoodModal good={editGood} onOk={editGoodCompliteHandler} onCancel={editGoodCompliteHandler}/>}
            <div>
                <Space>
                    Магахин <Select options={shops} defaultValue={''} onChange={changeShop} placeholder='Выберите магазин' size="small" style={{width: '150px'}}/>
                    Группа <Select options={legacyGroups} value={legacyGroupsSelect} onChange={g=>setLegacyGroupsSelect(g)} placeholder='Выберите группу' size="small" style={{width: '150px'}}/>
                    <Button onClick={compareAllGoods}><BarcodeOutlined /></Button>
                    <Checkbox value={comparedGoodsFilter} onChange={_=>setComapredGoodsFilter(!comparedGoodsFilter)} size='small'/>
                    Отобразить сопоставленные
                </Space>
                <div style={{height: 'calc(100vh - 100px)', overflowY: 'auto'}}>
                    <List 
                        bordered={true}
                        size="small"
                        dataSource={legacyGoods}
                        rowKey={(item)=>item.uuid}
                        renderItem={(item) => (
                            <List.Item
                                style={{backgroundColor: item.isAdded ? 'lightgreen' : 'lightpink'}}
                                actions={getLegacyActionsButtons(item, findGoodByComparedBarcode, compareSelectionGood, addNewCompareGoods, removeGoodShop, openGetNamesModal, deleteLegacyGood)}
                                >
                                <div >
                                    {item.name}
                                    <span style={{paddingLeft: '20px'}}>{item.price}</span>
                                    <span style={{paddingLeft: '10px'}}>{item.lastSellStr}</span>
                                </div>
                            </List.Item>
                        )}
                        />
                </div>
            </div>
            <div>
                <Space>
                    <SearchComparedComponent setSearchValue={setSearchValue} />
                    <GroupsCompareModal onSelect={setCompareGroupId} />
                </Space>
                <div style={{height: 'calc(100vh - 100px)', overflowY: 'auto'}}>
                    <List 
                        bordered={true}
                        size="small"
                        dataSource={compareGoods}
                        rowKey={(item)=>item.id}
                        renderItem={(item) => (
                            <List.Item
                                style={{backgroundColor: item.selected ? 'lightgreen' : ''}}
                                onClick={()=>onSelectedCompareGood(item)}
                                >
                                <div style={{width: '100%'}}>
                                    {item.name}
                                    <span style={{paddingLeft: '20px'}}>{item.price}</span>
                                    <div style={{float: 'right', marginRight: '10px'}}>
                                        <Button onClick={_=>setEditGood(item)} size="small"><EditOutlined /></Button>
                                        <Button onClick={_=>deleteCompareGood(item.id)} size="small"><DeleteOutlined /></Button>
                                        <Button onClick={_=>openInfoDialog(item.id)} size="small"><DollarOutlined /></Button>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                        />
                </div>
            </div>
        </div>
    </Context.Provider>
}

export default TabBeerCompare;