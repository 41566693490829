import { Button, Checkbox, Input, List, Modal } from "antd";
import { useEffect, useRef, useState } from "react"
import $api, { $apiInventory } from "../../http";
const { Search } = Input;

function ProductSelection ({shopDbName, addGoods}) {
    const inputRef = useRef(null);
    const [goods, setGoods] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');

    const handleOk = () => {
        setIsOpen(false);
        addGoods(goods.filter(g=>g.checked));
    }
    const handleChacked = ({uuid}, checked) => setGoods(goods.map(g=>{
        if(g.uuid===uuid)
            return {...g, checked};
        return {...g};
    }) );

    const handleSearch = async (value, _e, info) => {
        if(value==='') return;
        const resp = await $api.get('/shops/manual/find-goods?isDeleted=false&find='+value,{
            headers: {shopDbName}
        });
        const _goods = resp.data;
        setGoods(_goods.map(g=>({...g, checked: false}) ));
            if(_goods.length>1)
                setIsOpen(true);
            if(_goods.length===1)
                addGoods(_goods);
            setSearch('');
            /*
        $apiInventory(shopDbName).get("search-goods?search="+value)
        .then(result=>{
            const _goods = result.data;
            setGoods(_goods.map(g=>({...g, checked: false}) ));
            if(_goods.length>1)
                setIsOpen(true);
            if(_goods.length===1)
                addGoods(_goods);
            setSearch('');
        })
        .catch(console.error);
        */
    }

    return <>
        <Modal
            onOk={handleOk}
            onCancel={_=>setIsOpen(false)}
            open={isOpen} 
            title="Выберите товары">
                <List dataSource={goods} renderItem={(item) => 
                    <List.Item key={item.uuid}>
                        <Checkbox checked={item.checked} onChange={e=>handleChacked(item, e.target.checked)}/> {item.name} {item.price}р
                    </List.Item>
                }
                size="small"/>
        </Modal>
        <Search value={search} onChange={e=>setSearch(e.target.value)} allowClear onSearch={handleSearch} placeholder="Поиск по наименованию или штрихкоду"/>
    </>;
}

export default ProductSelection;