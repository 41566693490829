import { useEffect, useState } from "react";
import $api from "../../../http";
import { Button, Select, Space, Table } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { MainInventoryColumns } from "./Columns";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import RecalcInventoryComponent from "./components/RecalcInventoryComponent";


const getShops = async () => {
    const resp = await $api.get('/shops/manual/shops');
    const shops = resp.data.map(x=>({label: x.name, value: x.shopDbName}));
    shops.push(({label: '', value: ''}));
    return shops;
}

const getList = async (shopDbName)=>{
    if(shopDbName==='') return [];
    const resp = await $api.get('/shops/inventory',{ headers: { shopDbName }});
    return resp.data;
}

const changeInventory = (item, setInventoryList) => {
    setInventoryList(prev=>prev.map(x=>{
        if(x.id===item.id) return {...item};
        return {...x};
    }))
}

function InventoryList(){
    const navigate = useNavigate();
    const [shops, setShops] = useState([{label: '', value: ''}]);
    const [shopDbName, setShopDbName] = useState('');
    const [inventories, setInventories] = useState([]);
    
    useEffect(()=>{
        getShops().then(setShops).catch(console.error);
    }, []);

    useEffect(()=>{
        getList(shopDbName).then(setInventories).catch(e=>{
            setInventories([]);
            console.error(e);
        })
    }, [shopDbName]);

    const openSummary = (inventoryId) => navigate(`/shops/invntory-summary/${shopDbName}/${inventoryId}`);
    const openDocuments = (inventoryId) => navigate(`/shops/inventory-list/${inventoryId}/documents`, {state: {shopDbName}});

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'createAt',
            key: 'createAt',
            render: (_, item)=><>{dayjs(Date.parse(item.createAt)).format('DD.MM.YY')}</>
        },
        {
            title: 'Фактически',
            dataIndex: 'amountFact',
            key: 'amountFact',
        },
        {
            title: 'В базе',
            dataIndex: 'amountDb',
            key: 'amountDb',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (_, item) => <>{item.status===2 ? 'Завершен' : ''}</>
        },
        {
            title: 'Документы',
            dataIndex: 'documents',
            key: 'documents',
            render: (_, item) => <Button onClick={_=>openDocuments(item.id)} size="small">Документы</Button>
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (_, item) => <RecalcInventoryComponent inventoryId={item.id} shopDbName={shopDbName} onComplite={item=>changeInventory(item, setInventories)}/>
        },
    ]

    return <div>
        <Space style={{marginBottom: '10px', marginTop: '10px'}}>
            Магазин: <Select options={shops} onChange={setShopDbName} style={{width: '150px'}} size="small"/>
        </Space>
        <Table 
            dataSource={inventories} 
            columns={columns} 
            rowKey={"id"} 
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                  onDoubleClick: (event) => { navigate(`/shops/inventory-list/${record.id}/summary`, {state: {shopDbName: shopDbName}})}, // click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            pagination={false} 
            size="small"/>
    </div>
}

export default InventoryList;