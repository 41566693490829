import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

function useGetCompareGroups(){
    const [groups, setGroups] = useState([{label: '', value: 0}]);

    const getGroups = async () => {
        try{
            const response = await $api.get(GOODS_COMPARE_URL + '/groups');
            const data = response.data.map(gr=>({ label: gr.name, value: gr.id }));
            setGroups([...data, {label: '', value: 0}]);
        }
        catch(e){
            console.error(e);
        }
    };
    useEffect(() => {
        getGroups();
    }, []);

    return {groups, getGroups};
}

export default useGetCompareGroups;