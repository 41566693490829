import { $apiKegaCounterControll } from "../../http"

const getNotPhotoDocuments = async (shopId) => {
    const resp = await $apiKegaCounterControll.get('/not-photo-documents?shopId='+shopId);
    return resp.data;
}

const appendPhoto = async (documentId, image) => {
    const resp = await $apiKegaCounterControll.post('/append-image', {documentId, image});
    
}

export {getNotPhotoDocuments, appendPhoto}