const defaultSelectValue = { value: "", label: ""};
const columns = [
    {
        title: "Наименование",
        dataIndex: "name",
        key: "uuid",
        render: (_,item)=><>{item.name}{item.article && <div style={{fontWeight: 300}}>{item.article}</div>}</>
    },
    {
        title: "Ед",
        dataIndex: "unitStr",
        key: "uuid"
    },
    {
        title: "Кол-во",
        dataIndex: "balance",
        key: "uuid"
    },
    {
        title: "Цена",
        dataIndex: "price",
        key: "uuid"
    },
]

export {columns, defaultSelectValue};