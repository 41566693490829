import dayjs from "dayjs";
import $api from "../../../http";

const getShops = async () => {
    const resp = await $api.get('/shops');
    return resp.data.map(x=>({ label: x.name, value: x.dbName }));
}

const getArrivals = async ({start, stop, shopDbName, supplierName}) =>{
    let query = `/shops/arrivals?start=${start.toISOString()}&stop=${stop.toISOString()}`;
    if(shopDbName!=='') query+="&shopDbName="+shopDbName;
    if(supplierName!=='') query+="$supplierName="+supplierName;
    const data = await $api.get(query);
    return data.data;
}
    

const getArrival = async (shopDbName, arrivalId) => {
    const response = await $api.get(`/shops/arrivals/${arrivalId}`, {
        headers: {
            shopDbName
        }
    });
    let data = response.data;
    data = {...data, dateArrival: dayjs(Date.parse(data.dateArrival))};
    return data;
}

const getNotProcessedPhoto = async () => 
    (await $api.get("/documents-source/photo")).data.map(x=>({...x, selected: false}));

const downloadNotProcessedPhoto = async () => {
    const resp = await $api.get("/shops/arrivals/photo/not-processed");
    const items = resp.data;
    items.forEach(item=>{
        const link = document.createElement('a');
        link.setAttribute('href',`https://s3.timeweb.cloud/3e1c6c45-beermag-docs/${item.fileName}`);
        link.setAttribute('download', 'download.png');
        link.setAttribute("target","_blank");
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

const getNotProcessedDocuments = async (shopDbName) =>
    (await $api.get('/documents-source/photo-xml-upload?shopDbName='+shopDbName)).data;

const deleteNotProccessedDocument = async (uuid) =>
    await $api.delete(`/documents-source/photo-xml-upload/${uuid}`);

export { getShops, getArrivals, getArrival, downloadNotProcessedPhoto, getNotProcessedPhoto, getNotProcessedDocuments, deleteNotProccessedDocument };