import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import NoteBookView from './views/notebook-view/NoteBookView';
import CommunicationControllView from './views/communiction-controll/CommunicationControllView';
import PriceCompare from './views/price-compare/PriceCompare';
import PricePrintChooseGoods from './views/price-print/PricePrintChooseGoods';
import { Menu } from 'antd';
import { DesktopOutlined, PieChartOutlined } from '@ant-design/icons';
import MainLaoyt from './MainLaoyt';
import PriceCompareNew from './views/price-compare-new/PriceCompareNew';
import BalanceNormalize from './views/balance-normalize/BalanceNirmalize';
import KegaCounterControll from './views/kega-counter-controll/KegaCounterControll';
import KegaCounterControllCreate from './views/kega-counter-controll/KegaCounterControll_Create';
import KegaCounterControllBalance from './views/kega-counter-controll/KegaCounterControll_Balance';
import KegaCounterControllPhoto from './views/kega-counter-controll-photo/KegaCounterControllPhoto';
import BeerCounterControll from './views/beer-counter-controll/BeerCounterControll';
import PercentArrivalProblems from './views/percent-arrival-problems/PercentArrivalProblems';
import KegaCounterControllReportByDay from './views/kega-counter-controll/keg-counter-controll-report-by-day/KegaCounterControllReportByDay';
import PriceNoCompareReport from './views/reports/price-no-compare/PriceNoCompareReport';
import RevaluationTasksPage from './views/shop-pages/revaluation-tasks/RevaluationTasksPage';
import MainPage from './MainPage';
import ArrivalListPage from './views/onlinecash/arrivals/ArrivalListPage';
import ArrivalPage from './views/onlinecash/arrivals/ArrivalPage';
import ComapreArrivalPage from './views/onlinecash/arrivals-processing/CompareArrivalPage';
import RevaluationPage from './views/onlinecash/revaluations/RevaluationPage';
import InventoryList from './views/onlinecash/inventory/InventoryList';
import InventorySummary from './views/onlinecash/inventory/InventorySummary';
import InventoryDocumentsPage from './views/onlinecash/inventory/InventoryDocumentsPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLaoyt />,
    children: [
      {
        index: true,
        element: <MainPage />//<NoteBookView />
      },
      {
        path: 'electron-notebook',
        element: <NoteBookView />
      },
      {
        path: 'price-print',
        element: <PricePrintChooseGoods />
      },
      {
        path: 'price-compare',
        element: <PriceCompareNew />
      },
      {
        path: 'balance-normalize',
        element: <BalanceNormalize />
      },
      {
        path: 'kega-counter-controll',
        children: [
          {
            index: true,
            element: <KegaCounterControll />
          },
          {
            path: 'document',
            element: <KegaCounterControllCreate />
          },
          {
            path: 'balance',
            element: <KegaCounterControllBalance />
          },
          {
            path: 'report-by-date/:shopId',
            element: <KegaCounterControllReportByDay />
          }
        ]
      },
      {
        path: 'beer-counter-controll',
        children: [
          {
            index: true,
            element: <BeerCounterControll />
          }
        ]
      },
      {
        path: 'percent-arrival-sell-analyze',
        element: <PercentArrivalProblems />
      },
      {
        path: 'reports',
        children: [
          {
            path: 'price-no-compare',
            element:<PriceNoCompareReport />
          }
        ]
      },
      {
        path: 'shops',
        children: [
          {
            path: 'revaluation-tasks',
            element: <RevaluationTasksPage />
          },
          {
            path: 'arrival-list',
            element: <ArrivalListPage />
          },
          {
            path: 'arrival-list/:id',
            element: <ArrivalPage />
          },
          {
            path: 'compare-arrivals/:uuid',
            element: <ComapreArrivalPage />
          },
          {
            path: 'revaluations/:revaluationId',
            element: <RevaluationPage />
          },
          {
            path: 'inventory-list',
            element: <InventoryList />
          },
          {
            path: 'inventory-list/:inventoryId/summary',
            element: <InventorySummary />
          },
          {
            path: 'inventory-list/:inventoryId/documents',
            element: <InventoryDocumentsPage />
          }
        ]
      },
    ]
  },
  { path: '/kega-photo/:shopId', element: <KegaCounterControllPhoto /> },
  /*
  { path: '/', element: <NoteBookView /> },
  { path: '/communication', element: <CommunicationControllView /> },
  { path: '/price-compare', element: <PriceCompare /> },
  { path: '/price-print', element: <PricePrintChooseGoods /> },
  */
])

root.render(
  <RouterProvider router={router}>
    
  </RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
