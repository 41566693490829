import { useEffect, useState } from "react";
import $api from "../../../http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Button, Space, Table } from "antd";
import useInventoryDetail from "./components/InventoryDetailComponent";
import useChangeQuantityFactModal from "./components/ChangeQuantityFactDialog";

const getSummary = async (shopDbName, inventoryId) => {
    const resp = await $api.get(`/shops/inventory/${inventoryId}/summary`,{
        headers: {shopDbName}
    });
    return resp.data;
}

function InventorySummary(){
    const {inventoryId} = useParams();
    const {state} = useLocation();
    const { shopDbName } = state;
    const [summary, setSummary] = useState(null);
    const navigate = useNavigate();
    const [detailModal, openDetailModal] = useInventoryDetail();
    const [openChangeQuantityModal, changeQuantityModal] = useChangeQuantityFactModal(shopDbName, inventoryId, setSummary);

    useEffect(()=>{
        getSummary(shopDbName, inventoryId).then(setSummary).catch(console.error);
    }, [inventoryId, shopDbName]);

    if(summary===null) return <></>;
    const onCell = (record, rowIndex) => {
        return {
          onClick: (event) => {
            openDetailModal(shopDbName, inventoryId, record.goodId)
          }, 
        };
      };
    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            width: 400,
            fixed: 'left',
            onCell,
        },
        {
            title: 'Фактически',
            dataIndex: 'quantityFact',
            key: 'quantityFact',
            width: 90,
            onCell: (record, rowIndex) => {
                return {
                  onClick: (event) => {
                    openChangeQuantityModal(record.goodId);
                  }, 
                };
              },
        },
        {
            title: 'В прогр.',
            dataIndex: 'quantityDb',
            key: 'quantityDb',
            width: 90,
            onCell,
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            width: 90,
            onCell,
        },
        {
            title: 'Недосдача',
            dataIndex: 'quantityMinus',
            key: 'quantityMinus',
            width: 90,
            render: (_, item) =>{
                if(item.quantityFact - item.quantityDb >= 0) return <></>;
                return <div style={{color: 'red', fontWeight: '600'}}>
                    {(item.quantityDb - item.quantityFact).toFixed(3)}
                </div>;
            },
            onCell,
        },
        {
            title: 'Пересорт',
            dataIndex: 'quantityPlus',
            key: 'quantityPlus',
            width: 90,
            render: (_, item) =>{
                if(item.quantityFact - item.quantityDb <= 0) return <></>;
                return <div style={{color: 'green', fontWeight: '600'}}>
                    {(item.quantityFact - item.quantityDb).toFixed(3)}
                </div>;
            },
            onCell,
        },
    ]

    return <>
        {detailModal}
        {changeQuantityModal}
        <div style={{marginBottom: '10px'}}>
            <Space><Button onClick={_=>navigate('/shops/inventory-list')}>Назад</Button></Space>
            <p>
                Дата: {dayjs(Date.parse(summary.createAt)).format('DD.MM.YY')} 
                Статус: {summary.status===2 && <>Завершен </>} 
                Сумма фактически: {summary.amountFact} в  программе {summary.amountDb}
            </p>
        </div>
        <Table 
            columns={columns} 
            dataSource={summary.positions} 
            rowKey={"id"} 
            pagination={false}  
            size="small"
            scroll={{
                x: 'calc(700px + 50%)',
                y: 'calc(100vh - 200px)',
              }}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    //openDetailModal(shopDbName, inventoryId, record.goodId)
                },
                  onDoubleClick: (event) => {}, // click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            />
    </>
}

export default InventorySummary;