import { Modal, Table } from "antd";
import { useState } from "react";
import $api from "../../../../http";
import dayjs from "dayjs";

const typeDocument = ['Инвенторизация', 'Приход', 'Списание', 'Продано за смену'];

const columns = [
    {
        title: 'Дата',
        dataIndex: 'dateAt',
        key: 'dateAt',
        render: (_, item)=><>{dayjs(Date.parse(item.dateAt)).format('DD.MM')}</>,
    },
    {
        title: 'Операция',
        dataIndex: 'typeDocument',
        key: 'typeDocument',
        render: (_, item)=><>{typeDocument[item.typeDocument]}</>,
    },
    {
        title: 'Кол-во',
        dataIndex: 'qunatity',
        key: 'qunatity',
        render: (_, item) => {
            if(item.typeDocument===2 || item.typeDocument===3) return <>- {item.qunatity}</>;
            return <>{item.qunatity}</>;
        }
    },
    {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
    },
]

const getDetail = async (shopDbName, inventoryId, goodId) => {
    const resp = await $api.get(`/shops/inventory/${inventoryId}/good-detail/${goodId}`, {
        headers: { shopDbName }
    });
    return resp.data;
}

function useInventoryDetail(){
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState([]);


    const openModal = (shopDbName, inventoryId, goodId) => {
        getDetail(shopDbName, inventoryId, goodId)
            .then(d=>{
                setDetail(d);
                setOpen(true);
            })
            .catch(e=>{
                console.error(e);
                setDetail([]);
            })
    }
    console.log(detail);
    const modal = <Modal open={open} onOk={_=>setOpen(false)} onCancel={_=>setOpen(false)}>
        <Table columns={columns} dataSource={detail} size="small" bordered pagination={false}/>
    </Modal>;

    return [modal, openModal];
}

export default useInventoryDetail;