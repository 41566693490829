import { Menu } from 'antd';
import { BarcodeOutlined, BorderOutlined, CloudDownloadOutlined, DesktopOutlined, DollarOutlined, HomeOutlined, PieChartOutlined, TrophyOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import BalanceNormalize from './views/balance-normalize/BalanceNirmalize';
import KegaCounterControll from './views/kega-counter-controll/KegaCounterControll';
import PercentArrivalProblems from './views/percent-arrival-problems/PercentArrivalProblems';
import KegaIcon from './assets/KegaIcon'


function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  
  const items = [
    getItem('Главная', '1', <HomeOutlined />),
    getItem('Эл тетрадь', '2', <PieChartOutlined />),
    getItem('Ценники', '3', <BarcodeOutlined />),
    getItem('Баланс', '4', <DollarOutlined />),
    //getItem('Сопоставление товара', '5', <CloudDownloadOutlined />),
    getItem('Контроль кег', '5', <KegaIcon />),
    getItem('Контроль пива', '6', <BorderOutlined />),
    getItem('Ошибки наценки', '7', <BorderOutlined />),
    getItem('Расхождение цен', '8', <DollarOutlined />),
  ]


function MainLaoyt() {
    const navigate = useNavigate();
    const clickMenuHandler = ({item, key}) => {
        if(key==='1') navigate("/");
        if(key==='2') navigate("/electron-notebook");
        if(key==='3') navigate("/price-print");
        if(key==='4') navigate("/balance-normalize");
        if(key==='_') navigate("/price-compare");
        if(key==='5') navigate("/kega-counter-controll");
        if(key==='6') navigate("/beer-counter-controll");
        if(key==='7') navigate('/percent-arrival-sell-analyze');
        if(key==='8') navigate('/reports/price-no-compare');
    }

    return <div style={{width: "100vw", minHeight: "100vh", display: 'grid', gridTemplateColumns: 'auto 1fr'}}>
    <div style={{height: "100vh"}}>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        inlineCollapsed={true}
        items={items}
        onClick={clickMenuHandler}
      />
    </div>
    <div style={{margin: '5px'}}>
      <Outlet />
    </div>
  </div>;
}

export default MainLaoyt;