import { Button, Modal } from "antd";
import Webcam from "react-webcam";
import { uploadPhoto } from "./KegaCounterControllRepository";
import { useEffect, useRef, useState } from "react";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  const KegaCounterControllGetScreenshot = ({onComplite, onCancel}) => {
    const [devices, setDevices] = useState([]);
    const [selectDevice, setSelectDevice] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const imgRef = useRef(null);
    const webcamRef = useRef(null);

    useEffect(()=>{
      const gettings = async () => {
        if(navigator.userAgent.match(/iPhone/i)){
          await navigator.mediaDevices.getUserMedia({
            video: {
            facingMode: { exact: 'environment' },
            },
          });
        }
         const devices = await navigator.mediaDevices.enumerateDevices();
         const list = devices.filter( ( device ) => device.kind === 'videoinput' ).map(x=>({label: x.label, id: x.deviceId}));
          setDevices(list);
          if(devices.length>0) setSelectDevice({label:devices[0].label, id: devices[0].id});
      }
      gettings();
    },[])

    return <Modal open={isOpen} width={1280} onCancel={_=>setIsOpen(false)}>
      {devices.map(x=><Button onClick={_=>setSelectDevice(x)}>{x.label}</Button>)}
      <Webcam ref={webcamRef} 
        screenshotQuality={1.0} 
        audio={false} 
        style={{width: '100%'}}
        screenshotFormat="image/jpeg" 
        videoConstraints={{ deviceId: selectDevice.id, width:1920, height: 1080 }}>
          {({ getScreenshot }) => (
            <Button
              onClick={() => {
                const canvas = document.createElement('canvas');
                const width = webcamRef.current.video.videoWidth;
                const height = webcamRef.current.video.videoHeight;
                canvas.width = width;
                canvas.height = height;
                const context = canvas.getContext("2d");
                context.fillStyle = "#AAA";
                context.fillRect(0, 0, canvas.width, canvas.height);
                context.drawImage(webcamRef.current.video, 0, 0, width, height);

                let data = canvas.toDataURL("image/png");
                data = data.replace("data:image/png;base64,","");
                uploadPhoto(data).then(result=>{
                  setIsOpen(false);
                  if(onComplite!==null) onComplite(result);
                  console.log(result);
                }).catch(alert);
              }}
            >
              Сфотографировать
            </Button>
          )}
      </Webcam>
    </Modal>
  }

  export default KegaCounterControllGetScreenshot;