import { Avatar, Button, List } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { appendPhoto, getNotPhotoDocuments } from "./KegaCounterControllPhotoRepository";
import GetScreenShot from "../../common-elemtns/GetScreenShot";
import dayjs from "dayjs";
import { VideoCameraFilled } from "@ant-design/icons";

const getDriveType = ({driveType}) => {
    if(driveType===0) return 'Приход';
    if(driveType===1) return 'Возврат';
    if(driveType===2) return 'Перемещение';
    return '';
}

function KegaCounterControllPhoto(){
    const navigate = useNavigate();
    const {shopId} = useParams();
    const [documents, setDocuments] = useState([]);
    const [selectDocumentId, setSelectDocumentId] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(()=>{
        getNotPhotoDocuments(shopId).then(setDocuments).catch(console.error);
    }, []);

    const getDocuments = () => getNotPhotoDocuments(shopId).then(setDocuments).catch(console.error);
    const openModalHandler = ({id}) => setSelectDocumentId(id);
    const complite = (imageBase64) => {
        setIsUploading(true);
        appendPhoto(selectDocumentId, imageBase64)
        .then(_=>{
            setIsUploading(false);
            setSelectDocumentId(null);
            getDocuments();
        })
        .catch(error=>{
            setIsUploading(false);
            alert("Ошибка\n"+error);
        });
    } 
    const cancel = () => setSelectDocumentId(null);

    return <div>
        <Button onClick={_=>navigate('/')}>Гланое меню</Button>
        <GetScreenShot isOpen={selectDocumentId!==null} onComplite={complite} onCancel={cancel} isUploading={isUploading} />
        <List itemLayout="horizontal" dataSource={documents}
            renderItem={(document, i) => 
                <List.Item key={document.id} onClick={_=>openModalHandler(document)}>
                    <List.Item.Meta
                        avatar={<Avatar style={{backgroundColor: 'red'}}><VideoCameraFilled /></Avatar>}
                        title={<>{dayjs(document.dateAt).format('DD.MM.YY')} Поставщик: {document.supplierName} Владелец: {document.kegaOwnerName}</>}
                        description={<><u>{getDriveType(document)}</u> {document.positions.map(p=><span style={{marginRight: '2px'}}>{p}</span>)}</>}
                    />
                </List.Item>
            }
        />
    </div>
}

export default KegaCounterControllPhoto;