import { Button, Checkbox, Divider, Modal, Space, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { getNotProcessedPhoto } from "./apis";
import { UploadOutlined } from "@ant-design/icons";
import $api from "../../../http";
import dayjs from "dayjs";

const ArrivalProcessingPhoto = () => {
    const [open, setOpen] = useState(false);
    const [photo, setPhoto] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [file, setFile] = useState(null);

    useEffect(()=>{
        if(open) getNotProcessedPhoto().then(setPhoto).catch(console.error);
    }, [open]);

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('xmlFile', file);
        const selectedPhoto = photo.find(x=>x.selected);
        if(selectedPhoto===undefined) return;
        formData.append('photoPositionId', selectedPhoto.id);
        formData.append('shopDbName', selectedPhoto.shopDbName);
        setProcessing(true);
        try{
            await $api.post('/documents-source/photo-xml-upload', formData);
        }
        catch(e) {
            window.alert("Ошибка");
            console.error(e);
        }
        setProcessing(false);
    }

    const uploadFileHandler = () =>{
        uploadFile().then(_=>{
            getNotProcessedPhoto().then(setPhoto).catch(console.error);
        }).catch(console.error);
    }

    const selectedChangeHandler = (item) => setPhoto(photo.map(x=>{
        if(item.id===x.id) return {...x, selected: !x.selected};
        return {...x};
    }))

    return <>
        <Button size="small" onClick={_=>setOpen(!open)}>Список фото</Button>
        <Modal open={open} onOk={_=>setOpen(false)} onCancel={_=>setOpen(false)}>
            {photo.map(x=><div key={x.id}>
                <Space>
                    <Checkbox value={x.selected} selected={x.selected} onChange={_=>selectedChangeHandler(x)} size='small'/>
                    <span>{dayjs(x.dateAt).format('DD.MM')}</span>
                    <span>{x.shopName}</span>
                    {x.fileName.map(x=>
                        <a key={x} href={"https://s3.timeweb.cloud/3e1c6c45-beermag-docs/" + x} target="_blank" download>Скачать</a>
                    )}
                </Space>
            </div>)
            }
            <input type="file" onChange={e=>setFile(e.target.files[0])} />
            <Divider />
            <Button onClick={uploadFileHandler} size="small">Загрузить</Button>
            { processing && <Spin />}
        </Modal>;
    </>;
}

export default ArrivalProcessingPhoto;