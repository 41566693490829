import { Modal } from "antd";
import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../http";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import dayjs from "dayjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const options = {
    responsive: true,
    plugins: {},
};

const getReport = async (shopDbName, uuid) => {
    try{
        const result = await $api.get(GOODS_COMPARE_URL +  `/arrival-in-day?shopDbName=${shopDbName}&uuid=${uuid}`);
        return result.data;
    }
    catch(e){
        console.error(e);
    }
    return [];
}

function ArrivalsDayModal({shopDbName, good}){
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [report, setReport] = useState({
        labels: [],
        datasets: [
          {
            label: 'Приходы',
            data: [],
            backgroundColor: 'rgba(99, 110, 255, 0.5)',
          },
        ],
    });
    useEffect(() => {
        if(shopDbName!=='' && good!==null){
            const {uuid, goodName} = good;
            setTitle(goodName);
            getReport(shopDbName, uuid).then(data=>{
                const labels = data.map(x=>dayjs(Date.parse(x.day)).format('DD.MM.YY'));
                const values = data.map(x=>x.count);
                setReport({
                    labels,
                    datasets: [
                      {
                        label: 'Приходы',
                        data: values,
                        backgroundColor: 'rgba(99, 110, 255, 0.5)',
                      },
                    ],
                });
                setOpen(true);
            });
        }
    }, [shopDbName, good]);

    const close = () => setOpen(false);

    if(good===null) return <></>;

    return <Modal title={title} open={open} onOk={close} onCancel={close}>
        <Bar data={report} options={options} />
    </Modal>
}

export default ArrivalsDayModal;