import { Input } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";

const {Search } = Input;

const SearchComparedComponent = ({setSearchValue}) => {
    const [search, setSearch] = useState('');
    const debounceSearchValue = useDebounce(search, 500);
    useEffect(()=>{
        setSearchValue(search);
    }, [debounceSearchValue]);
    
    return <Search value={search} onChange={e=>setSearch(e.target.value)} size="small" />
}

export default SearchComparedComponent;