import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";
import { Select } from "antd";

const getGroups = async (shopDbName, priceOffset) => {
    if(shopDbName==='') return [{label: '', value: ''}];
    let offset = priceOffset==='' ? '0' : priceOffset;
    const response = await $api.get(GOODS_COMPARE_URL + '/reports/price-less-original/groups?shopDbName=' + shopDbName + '&priceOffset=' + offset);
    const result = response.data.map(x=>({label: x.name, value: x.id}));
    result.push({label: '', value: ''});
    return result;
}

const GoodGroupList = ({shopDbName, setGroupId, priceOffset}) => {
    const [groups, setGroups] = useState([{label: '', value: ''}]);
    useEffect(()=>{
        getGroups(shopDbName, priceOffset).then(setGroups).catch(console.error);
    }, [shopDbName]);
    const changeSelectHandler = (groupId) => setGroupId(groupId);

    return <>Группа: <Select options={groups} onChange={changeSelectHandler} size="small" style={{width: '200px'}} /></>
}

export default GoodGroupList;