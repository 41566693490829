import { useLocation, useNavigate, useParams } from "react-router-dom";
import $api from "../../../http";
import { useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Divider, Select, Space, Spin } from "antd";

const getDocuments = async (shopDbName, inventoryId)=> {
    const resp = await $api.get(`/shops/inventory/${inventoryId}/documents`,{
        headers: {shopDbName}
    });
    const data = resp.data;
    let i = 0;
    data.arrivals = data.arrivals.map(x=>({...x, num: i++, selected: false}));
    i=0;
    data.writeofs = data.writeofs.map(x=>({...x, num: i++, selected: false}));
    i=0;
    data.shifts = data.shifts.map(x=>({...x, num: i++, selected: false}));
    i=0;
    data.money = data.money.map(x=>({...x, num: i++, selected: false}));
    return data;
}

export default function InventoryDocumentsPage(){
    const {inventoryId} = useParams();
    const {state} = useLocation();
    const { shopDbName } = state;
    const navigate = useNavigate();
    const [documents, setDocuments] = useState(null);
    useEffect(()=>{
        getDocuments(shopDbName, inventoryId).then(setDocuments).catch(console.error);
    }, []);
    
    const backNavigate = () => navigate('/shops/inventory-list');
    if(documents===null) return <><Button onClick={_=>backNavigate()}>Назад</Button><br/><Spin /></>;
    const changeSelected = (num, typeDocument) => {
        if(typeDocument==='arrival')
            setDocuments(p=>({...p, arrivals:p.arrivals.map(x=>{
                if(x.num===num) return {...x, selected: !x.selected};
                return {...x};
            })}));
        if(typeDocument==='writeof')
            setDocuments(p=>({...p, writeofs:p.writeofs.map(x=>{
                if(x.num===num) return {...x, selected: !x.selected};
                return {...x};
            })}));
        if(typeDocument==='shift')
            setDocuments(p=>({...p, shifts:p.shifts.map(x=>{
                if(x.num===num) return {...x, selected: !x.selected};
                return {...x};
            })}));
        if(typeDocument==='money')
            setDocuments(p=>({...p, money:p.money.map(x=>{
                if(x.num===num) return {...x, selected: !x.selected};
                return {...x};
            })}));
    }

    const tabs = [
        {
            key: '1',
            label: 'Приходы: '+documents.amountArrivals,
            children: <p>{documents.arrivals.map(x=>{
                    return <div key={x.num}>
                        <Checkbox value={x.selected} onChange={_=>changeSelected(x.num, 'arrival')} size='small'/> 
                        {x.createAtStr} : {x.supplierName} : {x.amountSell}</div>;
                })}</p>,
        },
        {
            key: '2',
            label: 'Списания: '+documents.amountWriteofs,
            children: <p>{documents.writeofs.map(x=>{
                    return <div key={x.num}>
                        <Checkbox value={x.selected} onChange={_=>changeSelected(x.num, 'writeof')} size='small'/> 
                        {x.createAtStr} : {x.note} : {x.amount}</div>;
                })}</p>,
        },
        {
            key: '3',
            label: 'Смена нал: '+documents.amountShiftCash + ' безнал: ' + documents.amountShiftElectron,
            children: <p>{documents.shifts.map(x=>{
                    return <div key={x.num}>
                        <Checkbox value={x.selected} onChange={_=>changeSelected(x.num, 'shift')} size='small'/> 
                        {x.createAtStr} нал: {x.amountCash} электр: {x.amountElectron}</div>;
                })}</p>,
        },
        {
            key: '4',
            label: 'Изъятие: '+documents.amountMoney,
            children: <p>{documents.money.map(x=>{
                    return <div key={x.num}>
                        <Checkbox value={x.selected} onChange={_=>changeSelected(x.num, 'money')} size='small'/> 
                        {x.createAtStr} : {x.note} электр: {x.amount}</div>;
                })}</p>,
        },
    ];

    return <>
        <div>
            <div>Предыдущая инвенторизация сумма товара: {documents.prevGoodAmount} денег в кассе: {documents.prevMoneyAmount}</div>
            <div>Текущая инвенторизация сумма товара: {documents.curGoodAmount} денег в кассе {documents.curMoneyAmount}</div>
            <div>Призоды: {documents.amountArrivals}</div>
            <div>Списания: {documents.amountWriteofs}</div>
            <div>Переоценка: {documents.amountRevaluations}</div>
            <div>Смены безнал: {documents.amountShiftElectron} наличные: {documents.amountShiftCash}</div>
            <div>Выплаты: {documents.amountMoney}</div>
        </div>
        <Divider/>
        <Collapse items={tabs} size="small" />
    </>
}