import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { deleteNotProccessedDocument, getNotProcessedDocuments } from "./apis";
import { Button, Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

const columns = (deletePositon) => [
    {
        title: '№',
        dataIndex: 'num',
        key: 'num',
    },
    {
        title: 'Дата',
        dataIndex: 'dateArrival',
        key: 'dateArrival',
        render: (_, item) => <>{dayjs(Date.parse(item.dateArrival)).format('DD.MM.YY')}</>
    },
    {
        title: 'Поставщик',
        dataIndex: 'supplierName',
        key: 'supplierName',
    },
    {
        title: 'Сумма',
        dataIndex: 'sumArrival',
        key: 'sumArrival',
    },
    {
        title: '',
        key: 'actions',
        render: (_, item) => <><Button onClick={_=>deletePositon(item.id)} size="small"><DeleteOutlined /></Button></> 
    },
]

function ArrivalNotProcessedTable({shopDbName}){
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    useEffect(()=>{
        getNotProcessedDocuments(shopDbName).then(setRows).catch(console.error);
    }, [shopDbName]);

    const reload = () =>
        getNotProcessedDocuments(shopDbName).then(setRows).catch(console.error);

    const deletePositionHandler = (uuid) => {
        if(window.confirm('Удалить?'))
            deleteNotProccessedDocument(uuid)
            .then(_=> getNotProcessedDocuments(shopDbName).then(setRows).catch(console.error))
            .catch(console.error);
    }

    return <div style={{marginTop: '10px', marginBottom: '10px'}}>
        <Space>
            <label>Список не обработанных документов</label>
            <Button onClick={_=>reload()} size="small">Обновить</Button>
        </Space>
        <Table 
            columns={columns(deletePositionHandler)} 
            dataSource={rows}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => { }, // click row
                  onDoubleClick: (event) => { navigate("/shops/compare-arrivals/"+ record.id) }, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            size="small" pagination={false} />
    </div>;
}

export default ArrivalNotProcessedTable;