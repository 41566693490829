import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

function useLegacyGroups(shopDbName){
    const [groups, setGroups] = useState([{label: '', value: 0}]);
    useEffect(() => {
        const getGroups = async () => {
            try{
                const response = await $api.get(GOODS_COMPARE_URL + '/legacy-groups?shopDbName='+shopDbName);
                const data = response.data.map(x=>({label: x.name, value: x.id}));
                setGroups([...data, {label: '', value: 0}]);
            }
            catch(e){
                console.error(e);
            }
        };
        if(shopDbName!='')
            getGroups();
        else
            setGroups([{label: '', value: 0}]);
    }, [shopDbName]);
    return groups;
}

export default useLegacyGroups;