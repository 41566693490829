import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, DatePicker, Input, Select, Space, Table } from "antd";
import $api from "../../../http";
import { BarcodeOutlined, CloseOutlined, CloseSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import _debounce from "lodash.debounce";
import FindGoodBarcodeModal from "./FindGoodBarcodeModal";
import UploadEgaisModal from "./UploadEgaisModal";
import CreateGoodComponent from "../../../components/create-good/CreateGoodComponent";

const defaultArrival= {
    id: 0,
    num: '',
    dateArrival: dayjs(),
    supplierId: '',
    supplierName: '',
    positions: []
}

const ndsOptions = [{label: '', value: ''}, {label: 'Без ндс', value: 'Без ндс'}, {label: '20%', value: '20%'}, {label: '10%', value: '10%'}];
function calcNds(price, nds){
    if(price==='') return '';
    const priceFloat = parseFloat(price);
    if(isNaN(priceFloat)) return 0;
    if(nds==='20%') return (priceFloat + priceFloat * 0.2).toFixed(2);
    if(nds==='10%') return (priceFloat + priceFloat * 0.1).toFixed(2);
    return priceFloat.toFixed(2);
}

var _shopDbName = '';

const columns = (handleGoodSearch, changeGoodId, changeInputHandler, deletePosition, setSearchBarcodeItem, cancelComapre, changeNdsValue) => [
    {
        title: '',
        dataIndex: 'deletePositon',
        render: (_, item) => <Button onClick={_=>deletePosition(item)} size="small"><DeleteOutlined /></Button>
    },
    {
        title: '#',
        dataIndex: 'numPP',
        key: 'numPP',
    },
    {
        title: 'Товар',
        dataIndex: 'sourceName',
        render: (_, item) => <>
            {item.goodId===null && 
                <>
                    {item.sourceName} <br />
                    <div style={{marginBottom: '4px'}}>
                        <Button onClick={_=>setSearchBarcodeItem({...item})} size="small" style={{marginRight: '10px'}}>
                            <BarcodeOutlined />
                        </Button>
                        <Select
                            showSearch
                            placeholder='поиск'
                            style={{width: '300px'}}
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            onSearch={val => handleGoodSearch(item.numPP, val)}
                            onChange={(value)=>changeGoodId(item.numPP, value)}
                            notFoundContent={null}
                            options={item.searchGoods}
                            size="small"
                            />
                    </div>
                    <CreateGoodComponent priceSell={item.priceSell} shopDbName={_shopDbName} sourceName={item.sourceName} onSave={val=>{changeGoodId(item.numPP, val.id)}} />
                </>
            }
            {item.goodId!==null && <>
                {item.goodName} 
                <Button onClick={_=>cancelComapre(item.numPP)} size="small" style={{marginLeft: '15px'}}><CloseOutlined /></Button></>}
        </>
    },
    {
        title: 'Кол-во',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (val, item) => <Input value={val} onChange={e=>changeInputHandler(item, e.target.value, 'quantity')} size="small"/>
    },
    {
        title: 'Цена закупки',
        dataIndex: 'priceArrival',
        key: 'priceArrival',
        render: (val, item) => <Input value={val} onChange={e=>changeInputHandler(item, e.target.value, 'priceArrival')} size="small"/>
    },
    {
        title: 'Цена закупки с НДС',
        dataIndex: 'priceArrivalNds',
        render: (val, _)=><>{val}</>,
        key: 'priceArrivalNds',
    },
    {
        title: 'Цена продажи',
        dataIndex: 'priceSell',
        dataIndex: 'priceSell',
        render: (val, item) => <Input value={val} onChange={e=>changeInputHandler(item, e.target.value, 'priceSell')} size="small"/>
    },
    {
        title: 'Наченка',
        dataIndex: 'percent',
        render: (_, x) => (((x.priceSell/x.priceArrivalNds - 1) * 100).toFixed(2))
    },
    {
        title: 'Ндс',
        dataIndex: 'nds',
        render: (_, item) => <Select options={ndsOptions} value={item.nds} onChange={val=>changeNdsValue(item, val)} size="small" style={{width: '80px'}}/>
    },
    {
        title: 'Закупка',
        dataIndex: 'sumaArrival',
        render: (_, x) => (x.priceArrival * x.quantity).toFixed(2)
    },
    {
        title: 'Закупка ндс',
        dataIndex: 'sumaArrival',
        render: (_, x) => (x.priceArrivalNds * x.quantity).toFixed(2)
    },
    {
        title: 'Проадажа',
        dataIndex: 'sumSell',
        render: (_, x) => (x.priceSell * x.quantity).toFixed(2)
    }
]

const getArrival = async (uuid) => {
    const resp = await $api.get('/documents-source/photo-xml-upload/'+uuid);
    const data = resp.data;
    let numPP=0;
    return {...data, 
        dateArrival: dayjs(Date.parse(data.dateArrival)), 
        positions: data.positions.map(x=>
                ({...x, numPP: ++numPP, searchGoods: [{label: '', value: ''}], priceArrivalNds: calcNds(x.priceArrival, x.nds) })
            )
    };
}

const getSuppliers = async (shopDbName) => 
    (await $api.get('/shops/manual/suppliers',{
        headers:{shopDbName}
    })).data.map(x=>({
        label: x.name, value: x.id
    }));
const findGoods = async (find, shopDbName) =>
    (await $api.get('/shops/manual/find-goods?isDeleted=false&find='+find,{
        headers:{shopDbName}
    })).data.map(x=>({ label: x.name + ' ' + x.unitStr, value: x.id }))

const getGood = async (goodId, shopDbName) =>
    (await $api.get('/shops/manual/goods/'+goodId,{
        headers:{shopDbName}
    })).data

const save = async (arrival, uuid) => {
    if(arrival.supplierId===null)
        throw new Error(`Не заполнен поставщик`);
    arrival.positions.forEach(x=>{
        if(x.goodId===null) throw new Error(`Не сопоставлен товар: ${x.sourceName}`);
    });
    const response = await $api.post('/documents-source/create-arrival',
        {
            ...arrival, 
            positions: arrival.positions.map(x=>{
                const quantity = parseFloat(x.quantity);
                const priceSell = parseFloat(x.priceSell);
                const priceArrival = parseFloat(x.priceArrival);
                return {...x, quantity, priceArrival, priceSell};
            }),
            dateArrival: arrival.dateArrival.toISOString(), 
            sourceDocumentId: uuid
        });
    return response.data;
}

const saveCompareGoods = async (arrival, uuid) => {
    await $api.post('/documents-source/save-compare-goods',  {
            sourceDocumentId: uuid,
            positions: arrival.positions.filter(x=>x.goodId!==null).map(x=>({...x}))
        });
}

const calcPercentSell = (setArrival)=>{
    const percentStr = window.prompt("Введите процент наценки, без знака наценки");
    if(percentStr==='') return;
    const percent = parseInt(percentStr);
    if(isNaN(percent)) return;
    setArrival(prev=>({...prev, positions: prev.positions.map(x=>{
        const priceArrivalWithNds = parseFloat(x.priceArrivalNds);
        const priceSell = Math.ceil(priceArrivalWithNds + priceArrivalWithNds * percent / 100);
        return {...x, priceSell};
    })}))
}

function ComapreArrivalPage(){
    const {uuid} = useParams();
    const navigate = useNavigate();
    const [arrival, setArrival] = useState({...defaultArrival});
    const [suppliers, setSuppliers] = useState([{label: '', value: ''}]);
    const [goods, setGoods] = useState([{label: '', value: ''}]);
    const [searchBarcodeItem, setSearchBarcodeItem] = useState(null);
    _shopDbName = arrival.shopDbName;

    useEffect(()=>{
        getArrival(uuid).then(setArrival).catch(console.error);
    }, []);

    useEffect(()=>{
        getSuppliers(arrival.shopDbName).then(setSuppliers).catch(console.error);
    }, [arrival.shopDbName])

    const changeSullpier = (val) => setArrival({...arrival, supplierId: val});

    function searhGoodHandler(numPP, value){
        findGoods(value, arrival.shopDbName).then(goods=>{
            setArrival({...arrival, positions: arrival.positions.map(x=>{
                if(x.numPP===numPP) return {...x, searchGoods: goods};
                return {...x, searchGoods: [{label: '', value: ''}]};
            })})
        }).catch(console.error);
    }
    const debounceCallback = useCallback(_debounce(searhGoodHandler, 300));
    const changeSearchGoods = (numPP, value) => debounceCallback(numPP, value);
    
    const changeGoodId = (numPP, goodId) => {
        const good = getGood(goodId, arrival.shopDbName).then(good=>{
            const {name, price} = good;
            setArrival({...arrival, positions: arrival.positions.map(x=>{
                if(x.numPP===numPP) return {...x, goodId, goodName: name, priceSell: price};
                return {...x};
            })})
        }).catch(console.error)
    }

    const cancelComapre = (numPP) => {
        setArrival({...arrival, positions: arrival.positions.map(x=>{
            if(x.numPP===numPP) return {...x, goodId: null, goodName: null, priceSell: 0};
            return {...x};
        })})
    }

    const sumArrival = arrival.positions.reduce((sum, item)=>{
        if(parseFloat(item.quantity)!==isNaN & parseFloat(item.priceArrival)!==isNaN)
            return sum + parseFloat(item.quantity) * parseFloat(item.priceArrival);
        return sum;
    }, 0);
    const sumArrivalNds = arrival.positions.reduce((sum, item)=>{
        if(parseFloat(item.quantity)!==isNaN & parseFloat(item.priceArrivalNds)!==isNaN)
            return sum + parseFloat(item.quantity) * parseFloat(item.priceArrivalNds);
        return sum;
    }, 0);
    const sumSell = arrival.positions.reduce((sum, item)=>{
        if(parseFloat(item.quantity)!==isNaN & parseFloat(item.priceSell)!==isNaN)
            return sum + parseFloat(item.quantity) * parseFloat(item.priceSell);
        return sum;
    }, 0);
    const saveHandler = () => {
        save(arrival, uuid).then(response=>{
            const { sumArrival, sumSell, revaluationId } = response;
            window.alert(`Запишите в тетрадь: \n Закупка ${sumArrival} \n Продажа ${sumSell}`);
            if(revaluationId!==null && window.confirm(`Изменились цены продажи. Открыть документ переоценки сейчас?`)){
                navigate('/shops/revaluations/' + revaluationId, { state: {shopDbName: arrival.shopDbName} });
                return;
            }
            navigate('/shops/arrival-list')
        }).catch(e=>{
            console.error(e);
            window.alert(e);
        })
    }
    const saveCompareGoodsHandler = () => saveCompareGoods(arrival, uuid).then(_=>{}/*navigate('/shops/arrival-list')*/).catch(console.error);

    /// Изменение значений в строках
    const changeInputHandler = (item, newVal, typeField) => {
        const positions = arrival.positions.map(x=>{
            const val = newVal.replace(',','.');
            if(x.numPP===item.numPP && typeField==='quantity')
                return {...x, quantity: val, priceArrivalNds: calcNds(val, x.nds)};
            if(x.numPP===item.numPP && typeField==='priceArrival')
                return {...x, priceArrival: val, priceArrivalNds: calcNds(val, x.nds)};
            if(x.numPP===item.numPP && typeField==='priceSell')
                return {...x, priceSell: val};
            return {...x};
        })
        setArrival({...arrival, positions});
    }

    const changeNdsValue = (item, ndsVal) => {
        const positions = arrival.positions.map(x=>{
            if(item.numPP===x.numPP) return {...x, nds: ndsVal, priceArrivalNds: calcNds(x.priceArrival, ndsVal) };
            return {...x};
        })
        setArrival({...arrival, positions});
    }

    const deletePosition = (item) => {
        if(window.confirm(`Удалить ${item.goodName ?? item.sourceName}`))
            setArrival({...arrival, positions: arrival.positions.filter(x=>x.numPP!==item.numPP)})
    }

    const onSearched = (numPP, goodId, name, price) => {
        setArrival({...arrival, positions: arrival.positions.map(x=>{
            if(x.numPP===numPP) return {...x, goodId, goodName: name, priceSell: price};
            return {...x};
        })})
        setSearchBarcodeItem(null);
    }

    return <div>
        <FindGoodBarcodeModal item={searchBarcodeItem} shopDbName={arrival.shopDbName} onSearched={onSearched}/>
        <Button onClick={_=>saveHandler()}>Сохранить</Button>
        <Button onClick={_=>navigate('/shops/arrival-list')}>Отмена</Button>
        <Button onClick={_=>saveCompareGoodsHandler()} size="small" style={{marginLeft: '15px'}}>Созранить сопоставление</Button>
    <div>
        <Space>
            <label>Накладная</label>
            <Input value={arrival.num} placeholder="№"/>
            <label>дата</label>
            <DatePicker value={arrival.dateArrival}/>
        </Space>
    </div>
    <div>
        <Space>
            <label>Поставщик</label>
            {arrival.sourceSupplierName} <br/>
            <Select value={arrival.supplierId} options={suppliers} onChange={changeSullpier} size="small" style={{width: '150px'}}/>
        </Space>
    </div>
    <div>
        <Space>
        {`Закупка ${isNaN(sumArrival) ? 0 : sumArrival.toFixed(2)}
        Закупка с НДС ${isNaN(sumArrivalNds) ? 0 : sumArrivalNds.toFixed(2)} 
        Продажа ${isNaN(sumSell) ? 0 : sumSell.toFixed(2)}`}
        <Button onClick={_=>calcPercentSell(setArrival)} size="small">Пересчитать наценку</Button>
        </Space>
    </div>
    <Table 
        rowKey={item=>item.numPP} 
        columns={columns(changeSearchGoods, changeGoodId, changeInputHandler, deletePosition, setSearchBarcodeItem, cancelComapre, changeNdsValue)} 
        dataSource={arrival.positions} 
        size="small" pagination={false} />
</div>
}

export default ComapreArrivalPage;