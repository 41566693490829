import { Button, Card, Col, Divider, Flex, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
    const navigate = useNavigate();
    const items = [
        {
            charpter: 'Учетные документы',
            cards: [
                {title: 'Задачи переоценки', description: 'Выполните переоценку. по заданию администратора', adress: ''},
                {title: 'Учет кег', description: 'Тетрадь учета кег', adress: '/kega-counter-controll'},
                {title: 'Ценники', description: 'Печать ценников', adress: '/price-print'},
                {title: 'Приходы', description: 'Документы приходов', adress: '/shops/arrival-list'},
                {title: 'Инвенторизация', description: 'Список инвенторизаций', adress: '/shops/inventory-list'},
            ]
        },
        {
            charpter: 'Отчеты',
            cards: [
                {title: 'Электронная тетрадь', description: 'Тетрадь движения документов на магазинах', adress: '/electron-notebook'},
                {title: 'Контроль пива', description: 'Отчет о запасах пива в магазинах', adress: '/beer-counter-controll'},
                {title: 'Ошибки наценки', description: 'Выполните переоценку. по заданию администратора', adress: '/percent-arrival-sell-analyze'},
                {title: 'Расхождение цен', description: 'Отчет выявляющий расхождение цен на магазинах', adress: '/reports/price-no-compare'},
            ]
        },
        /*
        {
            charpter: 'Сервисные',
            cards: [
                {title: 'Баланс', description: '', adress: '/balance-normalize'}
            ]
        },
        */
    ];

    return <div style={{backgroundColor: '#f8f8f8', minHeight: '100vh'}}>
        {items.map(item=><>
            <Divider orientation="center" orientationMargin='0'>{item.charpter}</Divider>
            <Row gutter={16} justify='center'>
            {item.cards.map(x=>
                    <Col span={7} style={{marginBottom: '10px'}}>
                        <Card
                            bordered
                            size="small"
                        >
                            <Flex
                                vertical
                                align="flex-start"
                                justify="space-between"
                            >
                                <Typography.Title level={4} style={{margin: 0}}>
                                {x.title}
                                </Typography.Title>
                                {x.description}
                                <Divider orientation="center" orientationMargin='0' style={{margin: 4}}/>
                                <Button onClick={_=>navigate(x.adress)} type="primary">
                                Открыть
                                </Button>
                            </Flex>
                        </Card>
                    </Col>
                )}
            </Row>
        </>)}
    </div>
};

export default MainPage;