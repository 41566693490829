import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

const getShops = async()=>{
    const result = await $api.get(GOODS_COMPARE_URL+ '/shops');
    return result.data.map(x=>({label: x.name, value:x.shopDbName}));
}

function useGetShops(){
    const [shops, setShops] = useState([{label: '', value: ''}]);
    useEffect(()=>{
        getShops().then(setShops).catch(console.error);
    }, []);
    return shops;
}

export default useGetShops;