import { List, Modal } from "antd";
import $api, { GOODS_COMPARE_URL } from "../../../../http";
import { useState } from "react";

const getNames = async (barcodes) => {
    const result = await $api.get(GOODS_COMPARE_URL + '/legacy-find-barcode?barcodes='+barcodes.join(','));
    return result.data;
}

const useGetNamesByBarcodes = () =>{
    const [names, setNames] = useState([]);
    const [open, setOpen] = useState(false);

    const openModal = (item) => {
        const {barcodes} = item;
        getNames(barcodes).then(names=>{
            setNames(names);
            setOpen(true);
        })
        .catch(console.error);
    }

    const selectNameHandler = (name) => {
        
    }

    const closeHandler = () => setOpen(false);

    const modal = <Modal open={open} onOk={closeHandler} onCancel={closeHandler}>
        <List
            dataSource={names}
            size='small'
            renderItem={(name) => (<List.Item onClick={_=>selectNameHandler(name)}>{name}</List.Item>)}
            >
        </List>
    </Modal>;

    return [modal, openModal];
}

export default useGetNamesByBarcodes;