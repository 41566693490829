import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

function useGetCompareGoods(groupId, searchValue){
    const [goods, setGoods] = useState([]);
    const getGoods = async () => {
        if(groupId===0) return;
        try{
            const response = await $api.get(GOODS_COMPARE_URL + "/goods?groupId=" + groupId + "&search=" + searchValue);
            const data = response.data.map(g=>({...g, selected: false}));
            setGoods(data);
        }
        catch(e){
            console.error(e);
        }
    }
    useEffect(()=>{
        getGoods();
    },[groupId, searchValue]);

    const selectedGood = (item) => setGoods(prev=>prev.map(g=>{

        if(g.id===item?.id) return {...g, selected: true};
        return {...g, selected: false};
    }))

    return [goods, getGoods, selectedGood];
}

export default useGetCompareGoods;