import { Modal } from "antd";
import { useEffect, useState } from "react"
import $api from "../../http";
import dayjs from "dayjs";

function NoteBookReport({shopId, positionId, onClose}){
    const [report, setReport] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        const getReport = async () => {
            try{
                const result = await $api.get(`/notebook/report?shopId=${shopId}&positionId=${positionId}`);
                setReport(result.data);
                setIsOpen(true);
            }catch(e) {
                console.error("Get report", e);
            }
        };
        if(isOpen, shopId!=null & positionId!=null)
            getReport();
    }, [shopId, positionId]);

    const handleCancel = () => {
        setReport(null);
        setIsOpen(false);
        onClose();
    }

    if(report==null || !isOpen) return <></>;
    return <Modal open={isOpen} onCancel={handleCancel}  onOk={handleCancel}>
        <div>Начальное кол-во товаров: {report.inventoryStartGoodSum}</div>
        <div>Начальное денег в кассе: {report.inventoryStartCashSum}</div>
        <hr/>
        <div>Приходы: {report.arrivalsSum}</div>
        <div>Списания: {report.writeofsSum}</div>
        <div>Переоценки: {report.revaluationSum}</div>
        <div>Перемещения: {report.transportSum}</div>
        <div>Денег внесение: {report.monyIncoumeSum}</div>
        <div>Денег изъятие: {report.moneyOutcomeSum}</div>
        <div>Продажи нал: {report.shiftCashSum}</div>
        <div>Продажи безнал: {report.shiftElectronSum}</div>
        <hr/>
        <div>Инвенторизация {report.inventoryStopGoodSum} + {report.inventoryStopCashSum}</div>
    </Modal>;
}

export default NoteBookReport;