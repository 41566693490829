import { Button, Input, Space , Spin, Table, Typography } from "antd";
import { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import { getTasks, saveRevaluations } from "./utils";

const { Title } = Typography;
const getColumns = (onCountChange) => [
    {
        title: 'Наименование',
        dataIndex: 'goodLegacyName',
        key: 'goodLegacyName',
    },
    {
        title: 'Кол-во',
        dataIndex: 'count',
        key: 'count',
        render: (count, record) => <Input value={count} onInput={e=>onCountChange({taskId: record.taskId, count: e.target.value})} size="small" style={{width: '150px'}}/>
    },
    {
        title: 'Цена было',
        dataIndex: 'priceOld',
        key: 'priceOld',
    },
    {
        title: 'Цена было',
        dataIndex: 'priceNew',
        key: 'priceNew',
    },
]

const RevaluationTasksPage = () => {
    const [shopDbName, setShopDbName] = useState('');
    const [tasks, setTasks] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    useLayoutEffect(() => {
        const dbName = localStorage.getItem('default-shop-dbname');
        if(dbName===null) localStorage.setItem('default-shop-dbname', 'Shop7');
        setShopDbName(dbName ?? '');
    }, []);

    useEffect(()=>{
        getTasks(shopDbName).then(setTasks).catch(console.error);
    }, [shopDbName]);

    const changeInputCount = (item) => {
        const {taskId, count} = item;
        setTasks(tasks.map(x=>{
            if(x.taskId!==taskId) return {...x};
            return {...x, count};
        }))
    }
    const columns = getColumns(changeInputCount);

    const save = async () =>{
        setIsSaving(true);
        try{
            await saveRevaluations(tasks);
            setIsSaving(false);
            var _tasks = await getTasks(shopDbName);
            setTasks(_tasks);
        }
        catch(e){
            setIsSaving(false);
            console.error(e);
        }
    }

    return <>
        <Space style={{marginRight: '10px'}}>
            <Title level={4}>Задания переоценки</Title>
        </Space>
        {!isSaving ? <Button onClick={save} size="small">Сохранить</Button> : <Spin size="small" />}
        <Table columns={columns} dataSource={tasks} size="small"/>
    </>
}

export default RevaluationTasksPage;