import { useEffect, useRef, useState } from "react";
import { Modal, Checkbox, Select, Space, Input } from "antd";
import { $apiKegaCounterControll } from "../../../../http";

const getShopBySuppliers = async () => {
    const result = await $apiKegaCounterControll.get('/suppliers');
    const suppliers = result.data;
    const options = suppliers.filter(x=>x.isShop).map(x=>({label: x.name, value: x.id}));
    return [...options, {label: '', value: ''}];
}

const sendNewArrival = async ({shopId, dateAt, supplierId, ownerId, kegaTypeId, quantity}) => {
    if(quantity==='') window.alert('Не заполнено кол-во');
    const result = await $apiKegaCounterControll.post('/documents', {
        id: 0,
        dateAt: dateAt.format('YYYY-MM-DD'),
        supplierId,
        kegaOwnerId: ownerId,
        shopId,
        driveType: 0,
        positions: [{id:0, kegaTypeId, countKega: parseInt(quantity)}],
        images: []
    });
}

export default function ModalAddArrival({data, onSuccess, onCancel}){
    const {shopId, ownerName, ownerId, kegaType, kegaTypeId, } = data;
    const [shops, setShops] = useState([{label: '', value: ''}]);
    const [selectShop, setSelectShop] = useState('');
    const [movement, setMovement] = useState(false);
    const [quantity, setQuantity] = useState('');

    useEffect(()=>{
        getShopBySuppliers().then(setShops).catch(console.error);
    }, []);

    const successHandler = () =>
        sendNewArrival({...data, supplierId: selectShop==='' ? null : parseInt(selectShop), quantity}).then(onSuccess).catch(console.error);

    return <Modal 
        title={`Приход ${ownerName} ${kegaType}`} 
        open={true}
        onOk={successHandler}
        onCancel={onCancel}
        >
        <Space>
            <Checkbox checked={movement} onChange={_=>setMovement(!movement)} /> перемещение с другого магазина
            <Select onChange={setSelectShop} options={shops} style={{width: '150px'}} disabled={!movement}/>
        </Space>
        <Input value={quantity} onChange={e=>setQuantity(e.target.value)} placeholder="Количество"/>
    </Modal>
}