import axios from "axios";

const GOODS_COMPARE_URL = '/goods-compare';

const $api = axios.create({
    withCredentials: false,
    baseURL: "https://notebook.exp-tech.com/api"
});

const $apiInventory = (shopDbName) => axios.create({
    withCredentials: false,
    headers: {
        "shop-name": shopDbName
    },
    baseURL: "https://notebook.exp-tech.com/api-inventory"
});

const $apiPriceCompare = axios.create({
    withCredentials: true,
    baseURL: "https://notebook.exp-tech.com/api-price-compare"
})

const $apiBalanceNormalize = axios.create({
    withCredentials: true,
    baseURL: "https://notebook.exp-tech.com/api-balance-normalize"
})

const $apiKegaCounterControll = axios.create({
    withCredentials: true,
    baseURL: "https://notebook.exp-tech.com/api-kega-controll"
})

export default $api;
export {$apiInventory, $apiPriceCompare, $apiBalanceNormalize, $apiKegaCounterControll, GOODS_COMPARE_URL};