import { FolderOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, List, Modal, Select, Space } from "antd";
import { useRef, useState } from "react";
import useCompareGroups from "./hooks/useGetCompareGroups";
import $api, { GOODS_COMPARE_URL } from "../../../http";

function GroupsCompareModal({onSelect}){
    const [modalOpen, setModalOpen] = useState(false);
    const {groups, getGroups} = useCompareGroups();
    const newGroupInputRef = useRef(null);

    const addGroupHandle = async () => {
        if(newGroupInputRef.current.input.value==='') return;
        const newGroupName = newGroupInputRef.current.input.value;
        await $api.post(GOODS_COMPARE_URL + '/groups', { name: newGroupName });
        await getGroups();
    }

    const openModalHandler = () => setModalOpen(true);
    const closeHandler = () => setModalOpen(false);

    return <>
        <Select defaultValue={0} options={groups} onChange={onSelect} size="small" style={{width: '150px'}}/>
        <Button onClick={openModalHandler} size="small">
            <FolderOutlined /> Группы
        </Button>
        <Modal open={modalOpen} onCancel={closeHandler} onOk={closeHandler}>
            <Space size='small'>
                <Input ref={newGroupInputRef} placeholder="Новая группа" size="small"/>
                <Button onClick={addGroupHandle} size="small"><PlusOutlined /></Button>
            </Space>
            <List
                bordered
                size="small"
                dataSource={groups}
                rowKey={(item)=>item.value}
                renderItem={(item)=><List.Item>{item.label}</List.Item>}
                />
        </Modal>
    </>
}

export default GroupsCompareModal;