import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";
import dayjs from "dayjs";

function useLegacyGoods(shopDbName, groupId, viewComparedGoods){
    const [goods, setGoods] = useState([]);
    const getGoods = async () => {
        try{
            if(shopDbName=='' || groupId==0){
                setGoods([]);
                return;
            }
            const response = await $api.get(GOODS_COMPARE_URL + `/legacy-goods?shopDbName=${shopDbName}&groupId=${groupId}`);
            const data = response.data
                .filter(x=>!x.isAdded || viewComparedGoods)
                .map(g=>{
                    const lastSellStr = g.lastSell==null ? '' : dayjs(Date.parse(g.lastSell)).format('DD.MM.YY');
                    return {...g, lastSellStr};
                });
            setGoods(data);
        }
        catch(e){
            console.error(e);
            setGoods([]);
        }
    };
    useEffect(()=>{
        getGoods();
    }, [shopDbName, groupId, viewComparedGoods]);

    

    const compareAllGoodsIntoGroup = async () => {
        if(window.confirm("Спопоставить товары группы по штрих-коду?"))
            try{
                const response = await $api.get(GOODS_COMPARE_URL + `/legacy-compare-all-goods?shopDbName=${shopDbName}&groupId=${groupId}`);
                const data = response.data.map(g=>{
                    const lastArrivalStr = g.lastArrival==null ? '' : dayjs(Date.parse(g.lastArrival)).format('DD.MM.YY');
                    return {...g, lastArrivalStr};
                });
                setGoods(data);
            }
            catch(e){
                console.error(e);
            }
    }

    return [goods, getGoods, compareAllGoodsIntoGroup];
}

export default useLegacyGoods;