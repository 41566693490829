import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

function useShops(){
    const [shops, setShops] = useState([{option: '', value: ''}]);
    useEffect(()=>{
        const getShops = async () => {
            try{
                const response = await $api.get(GOODS_COMPARE_URL + '/shops');
                let data = response.data.map(x=>({label: x.name, value: x.shopDbName}));
                //data = data.push({label: '', value: ''});
                setShops([...data, {label: '', value: ''}]);
            }
            catch(e){
                console.error(e);
            }
        };
        getShops();
    }, []);
    return shops;
}

export default useShops;