import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { $apiKegaCounterControll } from "../../../http";
import { useLocation, useNavigation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import ModalAddArrival from "./modals/ModalAddArrival";
import ModalAddWriteof from "./modals/ModalAddWriteof";

const getReport = async (start, shopId) => {
    const result = await $apiKegaCounterControll.get(`/report-by-day?shopId=${shopId}&start=${start.format('DD.MM.YYYY')}&stop=${dayjs().format('DD.MM.YYYY')}`);
    return result.data;
}

const generateFirstColumn = (report) => <div>
    <div>Поставщики</div>
    <div>&nbsp;</div>
    {report[0].positions.map(r=><div className="report-by-date-div2" style={{whiteSpace: 'nowrap'}}>{r.ownerName} {r.kegaType}</div>)}
</div>

const generateColumn = (column, openArrivalModal, openWriteOfModal) => {
    const dateAt = dayjs(Date.parse(column.date));
    return <div>
        <div className="report-by-date-div" style={{textAlign: 'center'}}>{dateAt.format('DD.MM.YY')}</div>
        <div className="report-by-date-div" style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}>
            <div>П</div>
            <div>Р</div>
            <div>О</div>
        </div>
        {column.positions.map(r=><div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}>
            <div onClick={_=>openArrivalModal({dateAt, ...r})} className="report-by-date-div" style={{width: '40px'}}>
                {r.plusQuantity!==0 && <>{r.plusQuantity}</>}
                {r.plusQuantity===0 && <span>&nbsp;</span>}
            </div>
            <div onClick={_=>openWriteOfModal({dateAt, ...r})} className="report-by-date-div" style={{width: '40px'}}>
                {r.minusQuantity!==0 && <>{r.minusQuantity}</>}
            </div>
            <div className="report-by-date-div" style={{width: '40px'}}>{r.endQuantity!==0 && <>{r.endQuantity}</>}</div>
            </div>)}
    </div>
} 

function KegaCounterControllReportByDay(){
    const {shopId} = useParams();
    const {state} = useLocation();
    const {shop} = state;
    const [start, setStart] = useState(dayjs().add(-10, 'day'));
    const [report, setReport] = useState(null);
    const [arrivalDataToModal, setArrivalDataToModal] = useState(null);
    const [writeoflDataToModal, setWriteoflDataToModal] = useState(null);
    
    useEffect(()=>{
        getReport(start, shopId).then(setReport).catch(console.error);
    }, [shopId, start]);

    const openArrivalModal = (position) => 
        setArrivalDataToModal({shopId: parseInt(shopId), ...position});
    const arrivalAddSuccessHandler = () => getReport(start, shopId).then(e=>{
        setReport(e);
        setArrivalDataToModal(null);
    }).catch(console.error);

    const openWriteOfModal = (position) => 
        setWriteoflDataToModal({shopId: parseInt(shopId), ...position});
    const writeOfAddSuccessHandler = () => getReport(start, shopId).then(e=>{
        setReport(e);
        setWriteoflDataToModal(null);
    }).catch(console.error);
    
    return <>
        {arrivalDataToModal!==null &&
            <ModalAddArrival data={arrivalDataToModal} onSuccess={arrivalAddSuccessHandler} onCancel={_=>setArrivalDataToModal(null)}/>
        }
        {writeoflDataToModal!==null &&
            <ModalAddWriteof data={writeoflDataToModal} onSuccess={writeOfAddSuccessHandler} onCancel={_=>setWriteoflDataToModal(null)}/>
        }
        <Space>
            Магазин <u>{shop.label}</u> отчет с 
            <DatePicker value={start} onChange={setStart} format='DD.MM.YY' />
        </Space>
        {report!==null && <div style={{display: 'flex', width: 'calc(100vw - 140px)'}}>
            {generateFirstColumn(report)}
            <div style={{overflowX:'scroll', display:'grid', gridTemplateColumns: `repeat(${report.length + 1}, 1fr)`}}>
                {report.map(column => generateColumn(column, openArrivalModal, openWriteOfModal))}
            </div>
            </div>}
    </>;
}

export default KegaCounterControllReportByDay;