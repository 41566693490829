import { DatePicker, Input, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import $api from "../../../http";
import dayjs from "dayjs";

const columns = (deletePosition, changeInput) => [
    {
        title: '#',
        dataIndex: 'numPP',
        key: 'numPP',
    },
    {
        title: 'Наименование',
        dataIndex: 'goodName',
        key: 'goodName',
    },
    {
        title: 'Наименование',
        dataIndex: 'unitStr',
        key: 'unitStr',
    },
    {
        title: "Кол-во",
        dataIndex: "quantity",
        render: (_, item) => <Input value={item.quantity} onChange={e=>changeInput(item, e.target.value, 'quantity')} size="small"/>,
    },
    {
        title: "Кол-во",
        dataIndex: "priceOld",
        render: (_, item) => {
            if(item.id===0) return <Input value={item.priceOld} onChange={e=>changeInput(item, e.target.value, 'priceOld')} size="small"/>
            return <>{item.priceOld}</>;
        },
    },
    {
        title: "Кол-во",
        dataIndex: "sumOld",
        key: 'sumOld',
    },
    {
        title: "Кол-во",
        dataIndex: "priceNew",
        render: (_, item) => {
            if(item.id===0) return <Input value={item.priceNew} onChange={e=>changeInput(item, e.target.value, 'priceNew')} size="small"/>
            return <>{item.priceNew}</>;
        },
    },
    {
        title: "Кол-во",
        dataIndex: "sumNew",
        key: 'sumNew',
    },
]

const defaultRevaluation = {
    id: 0,
    createAt: dayjs(),
    status: 0,
    sumOld: 0,
    sumNew: 0,
    uuid: '',
    positions: []
}

const calcSum = (quantity, price)=>{
    const quantityFloat = parseFloat(quantity);
    const priceFloat = parseFloat(price);
    if(isNaN(quantityFloat) || isNaN(priceFloat)) return 0;
    return (quantityFloat * priceFloat).toFixed(2);
}

const getRvaluation = async (revaluationId, shopDbName) => {
    if(revaluationId===0)
        return {...defaultRevaluation};
    const resp = await $api.get(`/shops/revaluations/${revaluationId}`, {
        headers: { shopDbName }
    });
    let numPP = 1;
    const revaluation = resp.data;
    const positions = revaluation.positions.map(x=>({...x, numPP: numPP++, sumOld: x.quantity * x.priceOld, sumNew: x.quantity * x.priceNew}));
    const createAt = dayjs(Date.parse(revaluation.createAt));
    return {...revaluation, createAt, positions};
}

function RevaluationPage(){
    const {revaluationId} = useParams();
    const {state} = useLocation();
    const shopDbName = 'Shop10';
    const [revaluation, setRevaluation] = useState({...defaultRevaluation});

    useEffect(()=>{
        getRvaluation(revaluationId, shopDbName)
            .then(setRevaluation)
            .catch(console.error);
    }, []);

    const deletePositionHandler = () => {};
    const changeInput = (item, val, columnName) => {
        setRevaluation({...revaluation, positions: revaluation.positions.map(x=>{
            if(x.numPP!==item.numPP) return {...x};
            if(columnName==='quantity') return {...x, quantity: val, sumOld: calcSum(val, item.priceOld), sumNew: calcSum(val, item.priceNew) };
            if(columnName==='priceOld') return {...x, priceOld: val, sumOld: calcSum(item.quantity, val) };
            if(columnName==='priceNew') return {...x, priceNew: val, sumNew: calcSum(item.quantity, val) };
            return {...x};
        })})
    };

    const sumOld = revaluation.positions.reduce((sum, x)=>{
        const priceOld = parseFloat(x.priceOld);
        const quantity = parseFloat(x.quantity);
        if(isNaN(priceOld) || isNaN(quantity))  return sum;
        return sum + (priceOld * quantity);
    }, 0).toFixed(2);
    const sumNew = revaluation.positions.reduce((sum, x)=>{
        const priceNew = parseFloat(x.priceNew);
        const quantity = parseFloat(x.quantity);
        if(isNaN(priceNew) || isNaN(quantity))  return sum;
        return sum + (priceNew * quantity);
    }, 0).toFixed(2);

    return <div>
        <Space>
            <label>Дата</label>
            <DatePicker value={revaluation.createAt} onChange={e=>setRevaluation({...revaluation, createAt: e})} size="small"/>
        </Space>
        <div>Сумма было: {sumOld} Сумма стало: {sumNew}</div>
        <div style={{marginTop: '10px'}}>
            <Table dataSource={revaluation.positions} rowKey={item=>item.numPP} columns={columns(deletePositionHandler, changeInput)} />
        </div>
    </div>;
}

export default RevaluationPage;