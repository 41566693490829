import { render } from "@testing-library/react";
import { Button } from "antd";
import dayjs from "dayjs";

const dateToString = (d) => {
    console.log(d);
    if(d==null) return '';
    return dayjs(Date.parse(d)).format('DD.MM.YY');
}

const getPriceNoCompareColumns = (setPrice, createRevaluationPrice) => [
    {
        title: 'Наименование',
        dataIndex: 'nameLegacy',
        key: 'nameLegacy',
    },
    {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => <span onClick={_=>setPrice(record)}>{price}</span>
    },
    {
        title: 'Цена в маг.',
        dataIndex: 'priceLegacy',
        key: 'priceLegacy',
    },
    {
        title: 'Последняя продажа',
        dataIndex: 'lastSell',
        key: 'lastSell',
        render: date => <>{dateToString(date)}</>
    },
    {
        title: '',
        key: 'action',
        render: (_, record) => <Button onClick={_=>createRevaluationPrice(record)} size="small">Переоценить</Button>
    }
]

export default getPriceNoCompareColumns;