import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import $api from "../../http";
import { BarcodeOutlined, PlusOutlined } from "@ant-design/icons";

const units = [{label: '', value: ''}, {label: 'шт', value: 796}, {label: 'л', value: 112}, {label: 'кг', value: 166}];
const specialTypes = [{label: '', value: ''}, {label: 'нет', value: 0}, {label: 'пиво', value: 1}, {label: 'тара', value: 2}];
const layoutForm = {
    labelCol: { span: 8, },
    wrapperCol: { span: 16, },
    style: { maxWidth: 400, },
    initialValues: { remember: true, },
}

const getGoodGroups = async (shopDbName) => {
    const data = await $api.get('/shops/manual/good-groups', {headers: { shopDbName }});
    const result = data.data.map(x=>({label: x.name, value: x.id}));
    result.push({label: '', value: ''});
    return result;
}

function CreateGoodComponent({shopDbName, sourceName, priceSell, onSave}){
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [goodGroups, setGoodGroups] = useState([{label: '', value: ''}]);
    const unitPrevSelect = useRef();
    const groupIdPrevSelect = useRef();

    useEffect(()=>{
        if(shopDbName!=='')
            getGoodGroups(shopDbName).then(setGoodGroups).catch(console.error);
        unitPrevSelect.current = (localStorage.getItem('add-new-good-unit')===null ? '' : parseInt(localStorage.getItem('add-new-good-unit')));
        groupIdPrevSelect.current = (localStorage.getItem('add-new-good-groupId')===null ? '' : parseInt(localStorage.getItem('add-new-good-groupId')));
    }, [shopDbName]);

    const generateBarcode = async () => {
        const resp = await $api.get('/shops/manual/goods/generate-barcode',{ headers: { shopDbName }});
        form.setFieldsValue({barcode: resp.data, })
    }

    const save = async (goodForm) => {
        const price = parseFloat(goodForm.price);
        const vPackage = goodForm.vPackage===undefined ? null : goodForm.vPackage==='' ? null : parseFloat(goodForm.vPackage);
        if(isNaN(price) || isNaN(vPackage)) return;
        const resp = await $api.post('/shops/manual/goods', {...goodForm, price, vPackage, barcode: "" + goodForm.barcode + "", barcodes: [ "" + goodForm.barcode ]}, {
            headers: { shopDbName }
        })
        setOpen(false);
        onSave(resp.data);
    }

    const openCloseDialogHandle = () =>{
        unitPrevSelect.current = (localStorage.getItem('add-new-good-unit')===null ? '' : parseInt(localStorage.getItem('add-new-good-unit')));
        groupIdPrevSelect.current = (localStorage.getItem('add-new-good-groupId')===null ? '' : parseInt(localStorage.getItem('add-new-good-groupId')));
        setOpen(!open);
    }
    const compliteHandler = () => form.submit();
    
    


    return <>
        <Button onClick={openCloseDialogHandle} size="small"><PlusOutlined /> Создать товар</Button>
        <Modal open={open} onOk={compliteHandler} onCancel={openCloseDialogHandle} okText="Создать" cancelText="Отмена">
            <Form
                name="basic"
                form={form}
                {...layoutForm}
                autoComplete="off"
                size="small"
                onFinish={save}
            >
                <Form.Item
                    label="Наименование"
                    name="name"
                    initialValue={sourceName}
                    rules={[
                        { required: true, message: 'Заполните наименование', },
                    ]}
                    >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Группа"
                    name="goodGroupId"
                    initialValue={groupIdPrevSelect.current}
                    rules={[
                        { required: true, message: 'Заполните наименование', },
                    ]}
                    >
                    <Select onChange={e=>localStorage.setItem('add-new-good-groupId',e)} options={goodGroups}/>
                </Form.Item>

                <Form.Item
                    label="Ед. изм."
                    name="unit"
                    initialValue={unitPrevSelect.current}
                    rules={[
                        { required: true, message: 'Заполните наименование', },
                    ]}
                    >
                    <Select  onChange={e=>localStorage.setItem('add-new-good-unit',e)} options={units}/>
                </Form.Item>

                <Form.Item
                    label="Тип"
                    name="specialType"
                    initialValue={0}
                    rules={[
                        { required: true, message: 'Заполните наименование', },
                    ]}
                    >
                    <Select defaultValue={''} options={specialTypes}/>
                </Form.Item>

                <Form.Item
                    label="Объем тары"
                    name="vPackage"
                    rules={[
                        { required: false,},
                    ]}
                    >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Штрихкод"
                    name="barcode"
                    rules={[
                        { required: true, message: 'Заполните наименование', },
                    ]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                    <Button onClick={generateBarcode} size="small">
                        <BarcodeOutlined /> Сгенерировать
                    </Button>
                </Form.Item>

                <Form.Item
                    label="Цена"
                    name="price"
                    initialValue={priceSell}
                    rules={[
                        { required: true, message: 'Заполните наименование', },
                    ]}
                    >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default CreateGoodComponent;