import { Alert, Button, DatePicker, Input, Radio, Select, Space, Table, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { getKegTypes, getShops, getSuppliers, getkegaOwners, documentValidation, save, loadingDocument } from "./KegaCounterControllRepository";
import { DeleteFilled } from "@ant-design/icons";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import ChooseDefaultShop, { defaultShopArg } from "./ChooseDefaultShop";
import KegaCounterControllGetScreenshot from "./KegaCounterControllGetScreenshot";
import ScreenshotPage from "./ScreenshotPage";

const defaultDocument = {
    id: 0,
    create: dayjs(),
    driveType: 0,
    shopId: '',
    supplierId: '',
    kegaOwnerId: '',
    positions: [],
    images: []
}

var _kegTypes = null;
var _changeKegaType = null;
var _changeKegaCount = null;
var _removePosition = null;

const columns = [
    {
        title: '№',
        dataIndex: 'numPP',
        key: 'numPP',
        render: (_, position) => <>{position.numPP}<Button onClick={_=>_removePosition(position)} style={{marginLeft: "15px"}}><DeleteFilled color="red"/></Button></>
    },
    {
        title: 'Тип кеги',
        dataIndex: 'kegaTypeId',
        key: 'kegaTypeId',
        render: (_, {numPP, kegaTypeId}) => <Select onChange={e=>_changeKegaType(numPP, e)} options={_kegTypes} defaultValue={kegaTypeId} style={{width: '150px'}}/>
    },
    {
        title: 'Количество',
        dataIndex: 'countKega',
        key: 'countKega',
        render: (_, {numPP, countKega}) => <Input value={countKega} onInput={e=>_changeKegaCount(numPP, e.target.value)}/>
    },
]

function KegaCounterControllCreate() {
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState('');
    const [document, setDocument] = useState(defaultDocument);
    const [shops, setShops] = useState([{label: '', value: ''}]);
    const [selectShopIsDisable, setSelectShopIsDisable] = useState(false);
    const [suppliers, setSuppliers] = useState([{label: '', value: ''}]);
    const [kegOwners, setKegOwners] = useState([{label: '', value: ''}]);
    const [kegTypes, setKegTypes] = useState([{label: '', value: ''}]);
    const [openUploadPhotoDialog, setOpenUploadPhotoDialog] = useState(false);

    useEffect(() => {
        const _shopId = localStorage.getItem(defaultShopArg);
        getShops().then(s=>setShops(s));
        getSuppliers().then(s=>setSuppliers(s));
        getkegaOwners().then(s=>setKegOwners(s));
        getKegTypes().then(s=>{
            _kegTypes = s;
            setKegTypes(s)
        });
        const {id} = location.state;
        if(id!==0)
            loadingDocument(id).then(d=>setDocument(d));
        else{
            if(_shopId!==null && _shopId!=='')
                setDocument({...document, shopId: parseInt(_shopId)});
        }
        if(_shopId!==null && _shopId!=='')
            setSelectShopIsDisable(true);
    }, []);

    const selectDateHandler = (date) => setDocument({...document, create: date});
    const addPositionHandler = () => {
        let numPP = document.positions.length+1;
        let positions = document.positions;
        positions.push({ id: 0, numPP: numPP, kegaTypeId:0, countKega:'' });
        setDocument({...document, positions: [...positions] });
    }
    const removePositionHandler = (position) => setDocument({...document, positions: document.positions.filter(p=>p!==position)});

    const changeKegaType = (numPP, kegaTypeId) => {
        setDocument({...document, positions: document.positions.map(d=>{
            if(d.numPP===numPP) d.kegaTypeId=kegaTypeId;
            return {...d};
        })})
    }
    const changeKegCount = (numPP, countKega) => {
        setDocument({...document, positions: document.positions.map(d=>{
            if(d.numPP===numPP) d.countKega=countKega;
            return {...d};
        })})
    }
    _changeKegaType = changeKegaType;
    _changeKegaCount = changeKegCount;
    _removePosition = removePositionHandler;

    const saveHandler = () => {
            save(document).then(d=>{
                setDocument(d);
                navigate('/kega-counter-controll');
            }).catch(e=>setError(e.message));
        }
    
    const photoCompliteHandler = (model) => {
        setDocument({...document, images: [model]});
        setOpenUploadPhotoDialog(false);
    };

    return <>
        <div>
            <Button onClick={saveHandler}>Сохранить</Button>
            {
                document.images.map(d=><a download href={`https://notebook.exp-tech.com/api-kega-controll/get-image?uuid=${d.uuid}&year=${d.pathYear}&month=${d.pathMonth}`} target="_blank">скачать</a>)
            }
            <Button onClick={_=>setOpenUploadPhotoDialog(true)}>Фото</Button>
            {error!=='' && <Alert message={error} type="error" closable />}
        </div>
        {
            openUploadPhotoDialog && <KegaCounterControllGetScreenshot onComplite={photoCompliteHandler} />
        }
        <div>
            <Space wrap>
                <label>Дата</label>
                <DatePicker onChange={selectDateHandler} value={document.create} format='DD.MM.YYYY'/>
                <label>Тип документа</label>
                <Radio.Group onChange={e=>setDocument({...document, driveType: e.target.value})} value={document.driveType}>
                    <Radio value={0}>Приход</Radio>
                    <Radio value={1}>Возврат</Radio>
                    <Radio value={2}>Перемещение</Radio>
                </Radio.Group>
            </Space>
        </div>
        <div>
            <Space wrap>
                <label>Магазин</label>
                <Select defaultValue={document.shopId} disabled={selectShopIsDisable} value={document.shopId} onChange={e=>setDocument({...document, shopId: e})} options={shops} style={{width: '150px'}}/>
            </Space>
        </div>
        <div>
            <Space wrap>
                <label>Поставщик/Получатель</label>
                <Select defaultValue={document.supplierId} value={document.supplierId} onChange={e=>setDocument({...document, supplierId: e})} options={suppliers} style={{width: '150px'}}/>
            </Space>
        </div>
        <div>
            <Space wrap>
                <label>Владелец кег</label>
                <Select defaultValue={document.kegaOwnerId} value={document.kegaOwnerId} onChange={e=>setDocument({...document, kegaOwnerId: e})} options={kegOwners} style={{width: '150px'}}/>
            </Space>
        </div>
        <div>
            <Button onClick={addPositionHandler} size="small">Добавить позицию</Button>
        </div>
        <Table rowKey='numPP' columns={columns} dataSource={document.positions} />
    </>
}

export default KegaCounterControllCreate;