import { Tabs } from "antd";
import TabBeerRemains from "./tab-pages/TabBeerRemains";
import TabBeerComapre from "./tab-pages/TabBeerCompare";
import BeerCounterControllMobile from "./BeerCounterControllMobile";

const tab_remains_key = 'tab-remains';
const tab_settings_key = 'tab-settings';
const tab_comapre_key = 'tab-compare';

const tabs= [
    {
        key: tab_remains_key,
        label: 'Остатки',
        children: <BeerCounterControllMobile />
    },
    {
        key: tab_settings_key,
        label: 'Настройки',
        children: <></>
    },
    {
        key: tab_comapre_key,
        label: 'Сопоставление',
        children: <TabBeerComapre />
    },
]
const onChange = (key) => {
    console.log(key);
  };

const BeerCounterControllTabs = () =>
    <Tabs defaultActiveKey={tab_remains_key} items={tabs} onChange={onChange} />;

export default BeerCounterControllTabs;