import { $apiBalanceNormalize } from "../../http";

const getShops = async () => {
    try{
        const resp = await $apiBalanceNormalize.get("/shops");
        let result = resp.data.map(s=>({value: s.dbName, label: s.name}));
        result.push({value: 0, label: ""});
        return result;
    }
    catch(e){
        console.error(e);
    };
    return [{value: 0, label: ""}];
}

const getGroups = async(dbName) => {
    try{
        const resp = await $apiBalanceNormalize.get("/groups?dbName="+dbName);
        let result = resp.data.map(s=>({value: s.id, label: s.name}));
        result.push({value: 0, label: ""});
        return result;
    }
    catch(e){
        console.error(e);
    }
    return [{value: 0, label: ""}];
}

const normalize = async (dbName, goodGroupId) => {
    try{
        await $apiBalanceNormalize.get("/normalize?dbName="+dbName + "&goodGroupId="+goodGroupId);
        return "success";
    }
    catch(e) {
        console.error(e);
        return "error";
    }
}

const getBalance = async (dbName, goodGroupId) => {
    try{
        const result = await $apiBalanceNormalize.get("/balance?dbName="+dbName + "&goodGroupId="+goodGroupId);
        return result.data;
    }
    catch(e){
        console.error(e);
        return [];
    }
}

export {getShops, getGroups, normalize, getBalance};