import { List, Modal, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import { getShops } from "./KegaCounterControllRepository";

const defaultShopArg = 'default-shop';

function ChooseDefaultShop (){
    const [isOpen, setIsOpen] = useState(false);
    const [shops, setShops] = useState([{label: 'Все', value: ''}]);
    const [chooseShop, setChooseShop] = useState('');
    useEffect(()=>{
        if(localStorage.getItem(defaultShopArg)===null)
            setIsOpen(true);
        getShops().then(s=>setShops(s)).catch(console.error);
    }, []);

    const setShopHandler = () => {
        localStorage.setItem(defaultShopArg, chooseShop);
        setIsOpen(false);
    }

    if(!isOpen) return <></>;
    return <Modal title='Выберите магазин по умолчанию' open={isOpen} onOk={setShopHandler}>
        <Radio.Group onChange={e=>setChooseShop(e.target.value)}>
            <List dataSource={shops} size="small" bordered
                renderItem={(item) => <List.Item><Radio value={item.value}/>{item.label}</List.Item>}
                />
        </Radio.Group>
    </Modal>
}

export default ChooseDefaultShop;
export {defaultShopArg};