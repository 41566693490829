import { Button, Select, Space, DatePicker, Table, Badge, Divider } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getShops,  getkegaOwners, getDocumentList, documentDelete } from "./KegaCounterControllRepository";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import ChooseDefaultShop, { defaultShopArg } from "./ChooseDefaultShop";
const { RangePicker } = DatePicker;

const coluemns = [
    {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (id) => <>
            <Button onClick={_=>_openDocument(id)} size="small">Изменить <EditFilled color="red"/></Button>
            <Button onClick={_=>_deleteDocument(id)} size="small">Удалить <DeleteFilled color="red"/></Button>
        </> ,
    },
    {
        title: 'Дата',
        dataIndex: 'dateAt',
        key: 'dateAt',
        render: (dateAt) => <>{dayjs(dateAt).format('DD.MM.YY')}</>
    },
    {
        title: '',
        dataIndex: 'driveType',
        key: 'driveType',
        render: (driveType) => <>{driveType===0 ? 'Приход' : (driveType===1 ? 'Возврат' : 'Перемещение' )}</>
    },
    {
        title: 'Магазин',
        dataIndex: 'shopName',
        key: 'shopName',
    },
    {
        title: 'Поставщик',
        dataIndex: 'supplierName',
        key: 'supplierName',
    },
    {
        title: 'Владелец',
        dataIndex: 'kegaOwnerName',
        key: 'kegaOwnerName',
    },
    {
        title: 'Кеги',
        dataIndex: 'kegs',
        key: 'kegs',
        render: (kegs) => <>{kegs.map(k=><span style={{marginRight: '2px'}}>{k.kegaName} <strong>{k.count}</strong></span>)}</>
    },
]

var _openDocument = null;
var _deleteDocument = null;

const dateRangeDefaultValue = [dayjs().add(-14, 'day'), dayjs()]

function KegaCounterControll(){
    const navigate = useNavigate();
    const [period, setPeriod] = useState(dateRangeDefaultValue);
    const [shops, setShops] = useState([{label:'', value: ''}]);
    const [kegaOwners, setKegaOwners] = useState([{label: '', value: ''}]);
    const [shopId, setShopId] = useState('');
    const [kegaOwnerId, setKegaOwnerId] = useState('');
    const [documents, setDocuments] = useState([]);
    const [isLoadingDocument, setIsLoadingDOcuments] = useState(false);

    const createHandler = () => navigate('document', {state: {id: 0}});

    useLayoutEffect(()=>{
        const _shopId = localStorage.getItem(defaultShopArg);
        getShops().then(s=>{
            setShops(s);
            setShopId(_shopId===null ? '' : _shopId);
        });
        getkegaOwners().then(s=>setKegaOwners(s));
    }, []);

    useEffect(()=>{
        setIsLoadingDOcuments(true);
        getDocumentList(period[0], period[1], shopId, kegaOwnerId)
            .then(list=>{ setDocuments(list); setIsLoadingDOcuments(false); })
            .catch(e=>{ console.error(e); setIsLoadingDOcuments(false); });
    }, [period, shopId, kegaOwnerId]);

    const openDocument = (id) => navigate('document', {state: {id}});
    _openDocument = openDocument;
    const deleteDocument = (id) => {
        if(window.confirm("Удалить?"))
            documentDelete(id)
                .then(_=>setDocuments(documents.filter(x=>x.id!==id)))
                .catch(e=>{
                    window.alert("Ошибка удаления");
                    console.error(e);
                });
    };
    _deleteDocument = deleteDocument;

    const openPhotoPage = () => {
        if(shopId==''){
            window.alert('Не выбран магазин');
            return;
        }
        navigate('/kega-photo/'+shopId);
    }

    const openReportByDate = () => {
        if(shopId==='') {
            window.alert('Не выбран магазин');
            return;
        }
        const shop = shops.find(x=>x.value==shopId);
        navigate('report-by-date/'+shopId, {state: {shop}});
    }

    console.log({shopId, shops});
    return <div>
        <Space wrap>
            <RangePicker onChange={e=> setPeriod(e)} defaultValue={dateRangeDefaultValue} format='DD.MM.YYYY' />
            Магазин: <Select value={shopId} onChange={e=>setShopId(e)} options={shops} style={{width: '150px'}}/>
            Владелец кеги: <Select onChange={e=>setKegaOwnerId(e)} options={kegaOwners} style={{width: '150px'}}/>
        </Space>
        <div style={{marginTop: '5px', marginBottom: '5px'}}>
            <Space>
                <Button onClick={createHandler}>Создать</Button>
                <Button onClick={openPhotoPage}>Документы без вото</Button>
                Отчеты: 
                <Button onClick={openReportByDate}>Отчет тетрадь</Button>
                <Button onClick={_=>navigate("balance")}>Баланс</Button>
            </Space>
        </div>
        <Table rowKey='id' dataSource={documents} columns={coluemns} loading={isLoadingDocument} pagination={false} size="small"/>
        <ChooseDefaultShop />
    </div>
}

export default KegaCounterControll;