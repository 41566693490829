import { useEffect, useState } from "react";
import { Card, Input, Select, Space} from "antd";
import { MobileView } from "react-device-detect";
import $api, { GOODS_COMPARE_URL } from "../../http";
import useShops from "./tab-pages/hooks/useShops";
import CountSaleInDayModal from "./modals/CountSaleInDayModal";
import { BarChartOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ArrivalsDayModal from "./modals/ArrivalsDayModal";

const calcOrderAndEnough = (balance, countDayStr) => balance.map(g => {
    const countDay = Number.isInteger(parseInt(countDayStr)) ? parseInt(countDayStr) : 1;
    const shops = g.shops.map(s=>{
        let orderCount = Math.ceil(countDay * s.avgSaleInDay - s.count);
        orderCount = orderCount < 0 ? 0 : orderCount;
        let enoughDay = Math.floor(s.count / s.avgSaleInDay);
        return {...s, orderCount, enoughDay};
    });
    return {...g, shops};
})

const dateFormater = (date) => 
    dayjs(Date.parse(date)).format('DD.MM.YY')

function BeerCounterControllMobile(){
    const [positions, setPositions] = useState([]);
    const [countDay, setCountDay] = useState('7');
    const [selectShop, setSelectShop] = useState({label: '', value:''});
    const [selectGood, setSelectGood] = useState(null);
    const [selectArrivalGood, setSelectArrivalGood] = useState(null);
    const shops = useShops();
    useEffect(() => {
        const getBalance = async () => {
            const response = await $api.get(GOODS_COMPARE_URL + '/balance');
            const balance = calcOrderAndEnough(response.data, countDay);
            setPositions(balance);
        }
        getBalance();
    }, []);

    const onInputHandle = (e) => {
        const value = e.target.value;
        setCountDay(value);
        const balance = calcOrderAndEnough(positions, value);
        setPositions(balance);
    }

    const changeShopName = (value) => {
        setSelectShop(shops.find(s=>s.value===value));
    }

    return <>
        <Space>
            Кол-во дней: <Input value={countDay} onInput={onInputHandle} />
            Магазин: <Select options={shops} onChange={changeShopName} style={{width: '150px'}}/>
        </Space>
        <CountSaleInDayModal shopDbName={selectShop.value} good={selectGood} />
        <ArrivalsDayModal shopDbName={selectShop.value} good={selectArrivalGood} />
        {positions.map(g=><Card title={g.goodName} size="small">
            {g.shops.filter(s=>selectShop.label==='' || s.shopName===selectShop.label).map(s=><div>
                {selectShop.label==='' && s.shopName + ": "} {s.count} л 
                {s.orderCount>0 && <b style={{color: 'red'}}>, заказть {s.orderCount} л</b>}
                {(s.orderCount===0 & s.enoughDay>0) && <i>, остолсь на {s.enoughDay} дней</i>}
                <br/>
                <i style={{color: 'gray'}}>
                    Расход в день {s.avgSaleInDay.toFixed(2)}
                    <BarChartOutlined onClick={_=>setSelectGood({goodName: g.goodName, uuid: s.uuid})} style={{marginLeft: '10px'}}/>
                    <br />
                    Последний приход {dateFormater(s.lastArrival)} кол-во {s.countLastArrival}
                    <BarChartOutlined onClick={_=>setSelectArrivalGood({goodName: g.goodName, uuid: s.uuid})} style={{marginLeft: '10px'}} />
                </i>
            </div>)}
        </Card>)}
    </>
}

export default BeerCounterControllMobile;