import { BrowserView, MobileView } from "react-device-detect";
import BeerCounterControllTabs from "./BeerCounterControllTabs";
import BeerCounterControllMobile from "./BeerCounterControllMobile";

function BeerCounterControll(){

    return <>
        <MobileView>
            <BeerCounterControllMobile />
        </MobileView>
        <BrowserView>
            <BeerCounterControllTabs />
        </BrowserView>
    </>
}

export default BeerCounterControll;