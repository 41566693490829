import { Button, Spin } from "antd";
import $api from "../../../../http";
import { useState } from "react";

const recalc = async (inventoryId, shopDbName) => {
    if(shopDbName==='') return {}
    const resp = await $api.post('/shops/inventory/calc',{inventoryId},{
        headers: {shopDbName}
    });
    return resp.data;
}

function RecalcInventoryComponent({inventoryId, shopDbName, onComplite}){
    const [processing, setProcessing] = useState(false);
    const recalcHandler = () => {
        setProcessing(true);
        recalc(inventoryId, shopDbName)
            .then(data=>{
                setProcessing(false);
                onComplite(data);
            })
            .catch(e=>{
                onComplite({});
                console.error(e);
                setProcessing(false);
            })

    }

    return <> 
            {processing && <Spin size="small" />} 
            {!processing && <Button onClick={recalcHandler} size="small">Пересчитать</Button>}
        </>
}

export default RecalcInventoryComponent;