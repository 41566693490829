import { Button, Modal, Input, Checkbox, Space } from "antd";
import { useRef, useState } from "react";
import $api from "../../../http";

const { TextArea } = Input;

function UploadEgaisModal({shopDbName}){
    const xmlTextRef = useRef('');
    const [open, setOpen] = useState(false);
    const [typeDocument, setTypeDocument] = useState('egais');
    const [xmlText, setXmlText] = useState('');

    const openModal = () => setOpen(true);
    const sendHandler = async () => {
        if(xmlText!=='')
            await $api.post('/documents-source/egais-upload', {
                shopDbName,
                typeDocument,
                xmlText,
            });
        setOpen(false);
    }

    return <>
        <Button onClick={_=>setOpen(true)} size="small">Загрузить xml</Button>
        <Modal open={open} onOk={_=>sendHandler()} onCancel={_=>setOpen(false)}>
            <Space>
                <Checkbox checked={typeDocument==='egais'} onChange={_=>setTypeDocument('egais')} size='small' />
                <label>Егаис</label>
                <Checkbox checked={typeDocument==='edo'} onChange={_=>setTypeDocument('edo')} size='small' />
                <label>ЭДО</label>
            </Space>
            
            <TextArea 
                onChange={e=>setXmlText(e.target.value)}
                size="small" 
                style={{height: 150}}
                />
            <textarea onChange={e=>xmlTextRef.current = e.target.value} />
        </Modal>
    </>

    const modal = <Modal open={open} onOk={_=>sendHandler()} onCancel={_=>setOpen(false)}>
        <textarea onChange={e=>xmlTextRef.current = e.target.value} />
    </Modal>;

    return [openModal, modal];
}

export default UploadEgaisModal;