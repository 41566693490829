import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useShopDbNameStore = create(
    persist(
        (set, get) => ({
            shopDbName: '',
            setShopDbName: (shopDbName) => set({shopDbName}),
        }),
        {
            name: 'shop-db-name-store',
            storage: createJSONStorage(()=>localStorage),
        }
    )
)