import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";
import { Button, Divider, Table } from "antd";
import getPriceNoCompareColumns from "../constants/priceNoCompareColumns";
import MoreInformationDialog from "./MoreInformationDialog";
import { InfoCircleFilled } from "@ant-design/icons";

const getNoComparePositions = async (shopDbName, groupId, priceOffset) => {
    if(shopDbName==='' || groupId==='') return [];
    let offset = priceOffset==='' ? '0' : priceOffset;
    const response = await $api.get(GOODS_COMPARE_URL + `/reports/price-less-original/goods?shopDbName=${shopDbName}&groupId=${groupId}&priceOffset=${offset}`);
    let key=1;
    return response.data.map(x=>({...x, key: key++}));
}

const createRevaluationTask = async (shopDbName, good) => {
  await $api.post(GOODS_COMPARE_URL + '/tasks-of-revaluation', {shopDbName, targetPrice: good.price, ...good});
}

const setTargetPrice = async (goodId) => {
  const priceStr = window.prompt('Цена');
  if(priceStr==='' || priceStr===null) return;
  await $api.put(GOODS_COMPARE_URL + '/goods/target-price', {goodId, price: parseInt(priceStr)});
}

const PriceNoCompareTable = ({shopDbName, groupId, priceOffset}) => {
    const [positions, setPositions] = useState([]);
    const [openInfoDialog, infoDialogComaponent] = MoreInformationDialog();
    useEffect(() => {
        getNoComparePositions(shopDbName, groupId, priceOffset)
            .then(setPositions).catch(er=>{
                console.error(er);
                setPositions([]);
            });
    }, [shopDbName, groupId]);

    const createTaskHandler = (good) =>
      createRevaluationTask(shopDbName, good).then(_=>{

      }).catch(console.error);
    const setTargetPriceHandler = ({goodId}) => setTargetPrice(goodId)
      .then(_=>getNoComparePositions(shopDbName, groupId, priceOffset).then(setPositions).catch(console.error))
      .catch(console.error);

    const columns = getPriceNoCompareColumns(setTargetPriceHandler, createTaskHandler);

    return <>
      {infoDialogComaponent}
      <Table columns={columns} dataSource={positions}
      pagination={false} size="small"
      expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.name} цена в магазинах: {record.shopsSource.length>=10 ? 'все магзины' : record.shopsSource.join(', ')}
              <br/>
              <Button onClick={_=>openInfoDialog(record.goodId, record.price)} size="small">Подробнее</Button>
            </p>
          ),
          rowExpandable: (record) => true,
        }}/>
    </>
}

export default PriceNoCompareTable;