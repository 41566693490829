import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../http";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import dayjs from "dayjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {},
};

const getCountSaleInDay = async (shopDbName, uuid) => {
    if(uuid===undefined || uuid===null)
        return [];
    try{
        const response = await $api.get(GOODS_COMPARE_URL + `/countsale-in-day?shopDbName=${shopDbName}&uuid=${uuid}`);
        return response.data;
    }
    catch(e){
        console.error(e);
    }
}

function CountSaleInDayModal({shopDbName, good}){
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [data, setData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Продажи',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
    });
    useEffect(()=>{
        if(shopDbName!=='' && good!==null){
            const {uuid, goodName} = good;
            setTitle(goodName);
            getCountSaleInDay(shopDbName, uuid).then(data=>{
                const labels = data.map(x=>dayjs(Date.parse(x.day)).format('DD.MM.YY'));
                const values = data.map(x=>x.count);
                setData({
                    labels,
                    datasets: [
                      {
                        label: 'Продажи',
                        data: values,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                    ],
                });
            });
            setIsOpen(true);
        }
    }, [good]);

    return <Modal title={title} open={isOpen} onOk={_=>setIsOpen(false)} onCancel={_=>setIsOpen(false)}>
        <Bar data={data} options={options} />
    </Modal>
}

export default CountSaleInDayModal;