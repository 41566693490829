import { useRef, useState } from "react"
import $api, { GOODS_COMPARE_URL } from "../../../../http";
import { Checkbox, Input, Modal, Space, Table } from "antd";
import dayjs from "dayjs";
import { render } from "@testing-library/react";

const getInformation = async (goodId) => {
    const response = await $api.get(GOODS_COMPARE_URL + '/reports/price-less-original/more?goodId='+goodId);
    let key=1;
    return response.data.map(x=>({...x, key: key++}));
}

const getColumns = (setPrice, addTask) => [
    {
        title: 'Магазин',
        dataIndex: 'shopName',
        key: 'shopName',
    },
    {
        title: 'Наименование',
        dataIndex: 'goodName',
        key: 'goodName',
    },
    {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => <span onClick={_=>setPrice(record)}>{price}</span>
    },
    {
        title: 'Поставщик',
        dataIndex: 'supplier',
        key: 'supplier',
    },
    {
        title: 'Приход',
        dataIndex: 'lastArrival',
        key: 'lastArrival',
        render: date => <>{date==null ? '' : dayjs(Date.parse(date)).format('DD.MM.YY')}</>
    },
    {
        title: 'Цена прихода',
        dataIndex: 'priceArrival',
        key: 'priceArrival',
    },
    {
        title: 'Цена продажи',
        dataIndex: 'priceSell',
        key: 'priceSell',
    },
    {
        title: 'Наценка',
        dataIndex: 'percentSale',
        key: 'percentSale',
    },
    {
        title: 'Чек',
        dataIndex: 'lastSell',
        key: 'lastSell',
        render: date => <>{date==null ? '' : dayjs(Date.parse(date)).format('DD.MM.YY')}</>
    },
    {
        title: 'Цена в чеке',
        dataIndex: 'priceInCheck',
        key: 'priceInCheck',
    },
    {
        title: '',
        key: 'action',
        render: (_, record) => <span onClick={_=>addTask(record)}>task</span>
    }
]

const setPrice = async (shopDbName, uuid) => {
    const priceStr = window.prompt('Цена');
    if(priceStr==='' || priceStr===null) return;
    const price = parseInt(priceStr);
    await $api.put(GOODS_COMPARE_URL + '/legacy-goods', {shopDbName, uuid, price});
}

const createRevaluationTask = async (shopDbName, goodId, uuid) => {
    const price = window.prompt('Цена');
    if(price==='' || price===null) return;
    const targetPrice = parseInt(price);
    await $api.post(GOODS_COMPARE_URL + '/tasks-of-revaluation', {shopDbName, goodId, uuid, targetPrice});
}

const MoreInformationDialog = () => {
    const [info, setInfo] = useState([]);
    const [goodPrice, setGoodPrice] = useState('0');
    const [flagFilterPrice, setFlagFilterPrice] = useState(false);
    const [open, setOpen] = useState(false);

    const openDialog = (goodId, price) => {
        getInformation(goodId).then(x=>{
            setOpen(true);
            setFlagFilterPrice(false);
            setGoodPrice(price);
            setInfo(x);
        })
        .catch(console.error);
    }

    const closeHandler = () => setOpen(false);

    const reloadInformation = (goodId) => getInformation(goodId).then(setInfo).catch(console.error);
    const createRevalutionTaskHndle = ({shopDbName, goodId, uuid}) => 
        createRevaluationTask(shopDbName, goodId, uuid).then(_=>reloadInformation(goodId)).catch(console.error);
    const setPriceHandle = ({shopDbName, goodId, uuid}) => 
        setPrice(shopDbName, uuid).then(_=>reloadInformation(goodId)).catch(console.error);
    const columns = getColumns(setPriceHandle, createRevalutionTaskHndle);

    const infoWIthFIlter = info.filter(x=>!flagFilterPrice || x.price==goodPrice);
    const component = <Modal open={open} onOk={closeHandler} onCancel={closeHandler} width={1000}>
        <Checkbox checked={flagFilterPrice} onChange={_=>setFlagFilterPrice(!flagFilterPrice)} />
        <Input value={goodPrice} onChange={e=>setGoodPrice(e.target.value)} style={{width: '100px'}} size="small"/>
        <Table dataSource={infoWIthFIlter} columns={columns} pagination={false} size="small"/>
    </Modal>;

    return [openDialog, component];
}

export default MoreInformationDialog;