import { useEffect, useState } from "react";
import useGetShops from "./hooks/useGetShops";
import { Button, Input, List, Select, Space } from "antd";
import $api, { GOODS_COMPARE_URL } from "../../../http";
import ShopList from "./components/ShopList";
import GoodGroupList from "./components/GoodGroupList";
import PriceNoCompareTable from "./components/PriceNoCompareTable";

const getReport = async (shopDbName, page) => {
    if(shopDbName==='') return {countAll: 100, page: 0, items: []};
    const result = await $api.get(GOODS_COMPARE_URL + `/reports/price-no-comapre?shopDbName=${shopDbName}&page=${page}&count=100`);
    return result.data;
}

function PriceNoCompareReport(){
    const [shopDbName, setShopDbName] = useState('');
    const [groupId, setGroupId] = useState('');
    const [priceOffset, setPriceOffset] = useState('10');

    return <>
        <Space>
            <ShopList setShopDbName={setShopDbName}/>
            <GoodGroupList shopDbName={shopDbName} setGroupId={setGroupId} priceOffset={priceOffset}/>
            <Input value={priceOffset} onChange={e=>setPriceOffset(e.target.value)} size="small"/>
        </Space>
        <PriceNoCompareTable shopDbName={shopDbName} groupId={groupId} priceOffset={priceOffset}/>
    </>
}

export default PriceNoCompareReport;