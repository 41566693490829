import { Select } from "antd";
import { useEffect, useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

const getShops = async () => {
    const response = await $api.get(GOODS_COMPARE_URL + '/shops');
    const result = response.data.map(x=>({label: x.name, value:x.shopDbName}));
    result.push({label:'', value: ''});
    return result;
}

const ShopList = ({setShopDbName}) => {
    const [shops, setShops] = useState([{label:'', value: ''}]);
    useEffect(() => {
        getShops().then(setShops).catch(console.error);
    }, []);

    const changeSelectHandler = (shopDbName) => setShopDbName(shopDbName);

    return <>
        Магазин: <Select options={shops} onChange={changeSelectHandler} size="small" style={{width: '150px'}}/>
    </>;
}

export default ShopList;