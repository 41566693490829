import { useEffect, useState } from "react";
import $api from "../../http";
import { Modal } from "antd";
import dayjs from "dayjs";

const getSell = async (arrivalId, uuid) => {
    if(arrivalId===null && uuid===null) return {checks: [], sum: 0};
    try{
        const result = await $api.get(`/percent-arrival-sell-analyze/sell?arrivalId=${arrivalId}&uuid=${uuid}`);
        const {sum, checks} = result.data;
        let i=1;
        return {
            sum:sum.toFixed(2),
            checks:checks.map(x=>{
                return {...x, numPP: i++, price:x.price.toFixed(2), sum:x.sum.toFixed(2)}
            })
        }
    }
    catch(e){ console.error(e); }
    return {checks: [], sum: 0};
}

function PercentArrivalProblemsSellModal({arrivalId, goodName, uuid, onClose}){
    const [sell, setSell] = useState({checks: [], sum: 0});
    useEffect(()=>{
        getSell(arrivalId, uuid).then(x=>{
            setSell(x);
        });
    }, [arrivalId, uuid]);

    if(arrivalId===null || goodName===null || uuid===null) return <></>;
    return <Modal title={goodName} open={true} onCancel={onClose} onOk={onClose}>
        <b>Итого: {sell.sum}</b>
        <br/>
        {sell.checks.map(x=><div key={x.numPP}>
            {dayjs(Date.parse(x.dateSell)).format('DD.MM')}: {x.price} * {x.count} = {x.sum}
        </div>)}
    </Modal>
}

export default PercentArrivalProblemsSellModal;