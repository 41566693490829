import { DatePicker, Input, Space, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigation, useParams } from "react-router-dom";
import { getArrival } from "./apis";
import { arrivalTablePositionColumns } from "./constants";

function ArrivalPage(){
    const {id} = useParams();
    const {state} = useLocation();
    const { shopDbName } = state;

    const [arrival, setArrival] = useState({
        id:0, dateArrival: dayjs(), num: '', supplierId: '', supplierName: '', sumArrival: 0, sumSell: 0, positions: [], photo: [] 
    })

    useEffect(()=>{
        getArrival(shopDbName, id).then(setArrival).catch(console.error);
    }, []);

    return <>
        <div>
            <Space>
                <label>Дата: </label>
                <DatePicker defaultValue={arrival.dateArrival} size="small"/>
            </Space>
        </div>
        <div>
            <Space>
                <label>Номер: </label>
                <Input value={arrival.num} size="small"/>
            </Space>
        </div>
        <div>
            <Space>
                <label>Магазин: </label>
                <Input value={arrival.shopName} size="small"/>
            </Space>
        </div>
        <div>
            <Space>
                <label>Поставщик: </label>
                <Input value={arrival.supplierName} style={{minWidth: '200px'}} size="small"/>
            </Space>
        </div>
        <div>
            <Space>
                <label>Фото: </label>
                {arrival.photo.map(p=><a href={`https://s3.timeweb.cloud/3e1c6c45-beermag-docs/${p.photoFileName}`} style={{marginLeft: '10px'}}>Фото </a>)}
            </Space>
        </div>
        <div>
            <Table 
                dataSource={arrival.positions} 
                columns={arrivalTablePositionColumns} 
                footer={_=><>Итого закупка: <b>{arrival.sumArrival}</b> Итго продажа: <b>{arrival.sumSell}</b></>} 
                size="small" />
        </div>
    </>
}

export default ArrivalPage;