import { useEffect } from "react";
import { autoCompare, getGroups, getShops } from "./ApiRepository";
import { useState } from "react";
import { Button, Divider, Select, Space } from "antd";

function PriceCompareNew(){
    const [shops, setShops] = useState([{label: '', value: ''}]);
    const [groups, setGroups] = useState([{label: '', value: ''}]);
    const [selectDbName, setSelectDbName] = useState('');
    const [selectGroupId, setSelectGroupId] = useState('');
    const [notComapred, setNoCompared] = useState({total: '', items: []});

    useEffect(()=>{
        getShops().then(s=>setShops(s));
    }, []);

    useEffect(() => {
        getGroups(selectDbName).then(gr=>setGroups(gr));
    }, [selectDbName]);

    useEffect(() => {

    }, [selectDbName, selectGroupId]);

    const autoCompareHandler = () => autoCompare(selectDbName)
        .then(r=>alert(`Сопоставлено ${r.comparedCount} из ${r.totalCount}`))
        .catch(r=>console.error(r));

    return <div>
        <div>
            <Space>
                <Select options={shops} onChange={s=>setSelectDbName(s)} size="small" style={{width: "200px"}}/>
                <Button onClick={autoCompareHandler} size="small">Авосопоставление</Button>
            </Space>
        </div>
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
            <div>
                <Divider>Не сопоставленные</Divider>
                <Space wrap>
                    Выберите группу: <Select defaultValue={0} options={groups} size="small" style={{width: '200px'}}/>
                    {notComapred.total==='' && <label>Не сопоставлено {notComapred.total}</label>}
                </Space>
            </div>
            <div>
                <Divider>Для сопставления</Divider>
            </div>
        </div>
    </div>
}

export default PriceCompareNew;