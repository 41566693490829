import $api from "../../../http";

export default function getPositions(startDate, stopDate, selectShopId, setPositions){
    if(selectShopId===null || selectShopId===0)
        return;
    const startDateFormat = startDate.format('YYYY-MM-DD');
    const stopDateFormat = stopDate.format('YYYY-MM-DD');
    $api
        .get(`/notebook?start=${startDateFormat}&stop=${stopDateFormat}&shopid=${selectShopId}`)
        .then(result => {
            setPositions(result.data.map(x=>({...x, openReport: false, isProcessing: false}) ));
        })
        .catch(console.error);
}