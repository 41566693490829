import { useEffect, useState } from "react";
import { getBalance, getGroups, getShops, normalize } from "./BalanceNormalizeFunctions";
import { Button, Divider, Select, Space, Table } from "antd";

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ед',
      dataIndex: 'unit',
      key: 'unit',
      render: (_, {unit}) => {
        switch(unit){
            case 796: return "шт"; break;
            case 112: return "л"; break;
            case 166: return "rр"; break;
            default: return ""; break;
        };
      }
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
    },
  ];
  

function BalanceNormalize() {
    const [shops, setShops] = useState([{value: 0, label: ""}]);
    const [dbName, setDbName] = useState("");
    const [groups, setGroups] = useState([{value: 0, label: ""}]);
    const [selectGroupId, setSelectGroupId] = useState(0);
    const [balance, setBalance] = useState([]);

    useEffect(()=>{
        const _getShops = async ()=> {
            setShops(await getShops());
        }
        _getShops();
    }, []);

    useEffect(()=>{
        const _getGroups = async () => {
            if(dbName!=="")
                setGroups(await getGroups(dbName));
        }
        _getGroups();
    }, [dbName]);

    useEffect(()=>{
        const _getBalance = async () => {
            if(dbName!=="" && selectGroupId!==0){
                let items = await getBalance(dbName, selectGroupId);
                setBalance(items);
            }
        }
        _getBalance();
    }, [dbName, selectGroupId]);

    const normalizeHandler = async () => {
        if(dbName==="") return;
        await normalize(dbName, selectGroupId);
        if(selectGroupId===0) return;
        let items = await getBalance(dbName, selectGroupId);
        setBalance(items);
    } 

    return <div>
        <Space wrap>
            Выберите магазин: <Select options={shops} onChange={e=>setDbName(e)} size="small" style={{width: "150px"}}/>
            <Button  onClick={normalizeHandler} size="small">Пересчитать</Button>
            Выберите группу: <Select options={groups} onChange={e=>setSelectGroupId(e)} size="small" style={{width: "200px"}}/>
        </Space>
        <Divider />
        <Table rowKey="id" columns={columns} dataSource={balance} />
    </div>
}

export default BalanceNormalize;