import { useEffect, useLayoutEffect, useState } from "react";
import { getBalance, getShops } from "./KegaCounterControllRepository";
import { Select, Space, Table } from "antd";
import { defaultShopArg } from "./ChooseDefaultShop";

const columns = [
    {
        title: 'Магазин',
        dataIndex: 'shopName',
        key: 'shopName',
    },
    {
        title: 'Владелец',
        dataIndex: 'kegaOwnerName',
        key: 'kegaOwnerName',
    },
    {
        title: 'Кега',
        dataIndex: 'kegaName',
        key: 'kegaName',
    },
    {
        title: 'Кол-во',
        dataIndex: 'count',
        key: 'count',
    }
]

function KegaCounterControllBalance () {
    const [balance, setBalance] = useState([]);
    const [shopId, setShopId] = useState('');
    const [shops, setShops] = useState([{label: '', value: ''}]);
    useLayoutEffect(()=>{
        const _shopId = localStorage.getItem(defaultShopArg);
        if(_shopId!==null && _shopId!=='')
            setShopId(_shopId);
        getShops().then(setShops);
    }, []);

    useEffect(() => {
        getBalance(shopId).then(setBalance);
    }, [shopId]);
    
    return <>
        <Space>
            <Select defaultValue='' onChange={setShopId} value={shopId} options={shops} style={{width: '150px'}}/>
        </Space>
        <Table columns={columns} dataSource={balance} pagination={false}/>
    </> 
}

export default KegaCounterControllBalance;