export default function KegaIcon (){
    return <svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" style={{paddingTop: '8px', marginRight: '30px'}}>
        <path d="M58,33a1,1,0,0,1-1-1V28a1,1,0,0,1,2,0v4A1,1,0,0,1,58,33Z" fill='#363636'/>
        <path d="M60,29H56a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" fill="#363636"/>
        <path d="M62,40H58a1,1,0,0,1-1-1V37H52a1,1,0,0,1,0-2h6a1,1,0,0,1,1,1v2h2V35a2,2,0,0,0-2-2H52a1,1,0,0,1,0-2h7a4,4,0,0,1,4,4v4A1,1,0,0,1,62,40Z" fill="#363636"/>
        <path d="M39.236,7.434a53.164,53.164,0,0,0-24.472,0V48.566A51.927,51.927,0,0,0,27,50a51.927,51.927,0,0,0,12.236-1.434Z" fill="#ffbd4a"/>
        <path d="M3.465,11.858A5,5,0,0,0,1,16.169V39.831a5,5,0,0,0,2.465,4.311,38.757,38.757,0,0,0,5.166,2.485V9.373A38.757,38.757,0,0,0,3.465,11.858Z" fill="#ffbd4a"/>
        <path d="M50.535,12.16h0a38.8,38.8,0,0,0-5.166-2.483V46.93a38.839,38.839,0,0,0,5.167-2.485A5,5,0,0,0,53,40.135V16.472A5,5,0,0,0,50.535,12.16Z" fill="#ffbd4a"/>
        <path d="M39,50a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V49A1,1,0,0,1,39,50Z" fill="#363636"/>
        <path d="M15,50a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V49A1,1,0,0,1,15,50Z" fill="#363636"/>
        <path d="M45,48a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0V47A1,1,0,0,1,45,48Z" fill="#363636"/>
        <path d="M9,48a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0V47A1,1,0,0,1,9,48Z" fill="#363636"/>
        <path d="M27,38a117.218,117.218,0,0,1-12.1-.608A1,1,0,1,1,15.1,35.4a119.567,119.567,0,0,0,23.792,0,1,1,0,1,1,.208,1.989A117.218,117.218,0,0,1,27,38Z" fill="#363636"/>
        <path d="M39,20.6a.937.937,0,0,1-.1-.006,119.567,119.567,0,0,0-23.792,0,1,1,0,1,1-.208-1.989,121.183,121.183,0,0,1,24.208,0,1,1,0,0,1-.1,2Z" fill="#363636"/>
        <path d="M45,36.584a1,1,0,0,1-.166-1.986c1.119-.19,2.1-.381,2.96-.563a1,1,0,1,1,.414,1.957c-.88.186-1.891.383-3.04.578A1.068,1.068,0,0,1,45,36.584Z" fill="#363636"/>
        <path d="M48,21.986a1.064,1.064,0,0,1-.208-.021c-.857-.182-1.841-.373-2.96-.563a1,1,0,0,1,.334-1.972c1.149.2,2.16.392,3.04.578A1,1,0,0,1,48,21.986Z" fill="#363636"/>
        <path d="M9,36.584a1.068,1.068,0,0,1-.168-.014c-1.149-.2-2.16-.392-3.04-.578a1,1,0,1,1,.414-1.957c.857.182,1.841.373,2.96.563A1,1,0,0,1,9,36.584Z" fill="#363636"/>
        <path d="M6,21.986a1,1,0,0,1-.206-1.978c.88-.186,1.891-.383,3.04-.578A1,1,0,1,1,9.167,21.4c-1.119.19-2.1.381-2.96.563A1.064,1.064,0,0,1,6,21.986Z" fill="#363636"/>
        <path d="M49,58H39a1,1,0,0,1-1-1,3,3,0,0,0-3-3H19a3,3,0,0,0-3,3,1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V49a1,1,0,0,1,2,0v7h8.1A5.008,5.008,0,0,1,19,52H35a5.008,5.008,0,0,1,4.9,4H48V49a1,1,0,0,1,2,0v8A1,1,0,0,1,49,58Z" fill="#363636"/>
    </svg>
}