import { Input, Modal, Space } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import $api from "../../../http";

const numberArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
const findGood = async (find, shopDbName) =>
    (await $api.get('/shops/manual/find-goods?isDeleted=false&find='+find,{
        headers:{shopDbName}
    })).data;

function FindGoodBarcodeModal({item, shopDbName, onSearched}){
    const [open, setOpen] = useState(false);
    const [barcode, setBarcode] = useState('');
    const inputRef = useRef(null);
    useEffect(()=>{
        if(item!==null)
        {
            setOpen(true);
            setBarcode('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 500);
        }
            
    }, [item]);

    const closeHandle = () => {
        setOpen(false);
        //inputRef.current.value='';
        //onSearched();
    }
    const inputHandle = (e) => {
        if(e.key==="Enter") {
            /*
            const barcode = inputRef.current.input.value;
            inputRef.current.input.value='';
            */
            findGood(barcode, shopDbName)
                .then(goods=>{
                    if(goods.length===0) return;
                    const good = goods[0];
                    onSearched(item.numPP, good.id, good.name, good.price);
                })
                .catch(console.error)
            closeHandle();
        }
    }

    return <Modal open={open} onOk={closeHandle} onCancel={closeHandle}>
        <Space>
            <label>Отсканируйте штрих код</label>
            <Input value={barcode} onChange={e=>setBarcode(e.target.value)} onKeyDown={inputHandle} ref={inputRef} size="small"/>
        </Space>
    </Modal>
}

export default FindGoodBarcodeModal;