import { useEffect, useState } from "react";
import $api from "../../http";
import { Card, Switch } from "antd";
import dayjs from "dayjs";
import { BarChartOutlined, CheckSquareOutlined, FileTextFilled, FileTextOutlined } from "@ant-design/icons";
import PercentArrivalProblemsSellModal from "./PercentArrivalProblemsSellModal";

const getProblems = async () => {
    try{
        const result = await $api.get('/percent-arrival-sell-analyze');
        return result.data;
    }
    catch(e){
        console.error(e);
    }
    return [];
}

const solveProblem = async ({id, num}) => {
    if(!window.confirm(`Обработан ${num}?`)) return;
    try{
        await $api.post('/percent-arrival-sell-analyze', {arrivalId: id});
    }
    catch(e){ console.error(e); }
}

const renderTitle = (p, solved) => <div>
    <Switch onClick={_=>solved(p)} size="small" />  {p.shopName} №{p.num} от {dayjs(Date.parse(p.dateArrival)).format('DD.MM.YY')}
    <br />
    {p.supplierName}
</div>;

function PercentArrivalProblems () {
    const [problems, setProblems] = useState([]);
    const [toModal, setToModal] = useState({arrivalId: null, goodName: null, uuid: null});
    useEffect(() => {
        getProblems().then(setProblems);
    }, []);

    const solvedHandler = (p) => solveProblem(p).then(_=>{
        getProblems().then(setProblems);
    })
    
    const openModal = (arrivalId, {uuid, goodName}) => setToModal({arrivalId, uuid});
    const closeModal = () => setToModal({arrivalId: null, goodName: null, uuid: null});

    return <div>
        {toModal.arrivalId!==null &&
            <PercentArrivalProblemsSellModal onClose={closeModal} {...toModal} />
        }
        {problems.map(shop=>
            <div key={shop.id} style={{marginBottom: '30px'}}>
                <h3 style={{paddingLeft: '30px', marginBottom: '20px'}}>{shop.shopName}</h3>
                {shop.arrivals.map(a=>
                    <Card key={a.id} title={renderTitle(a, solvedHandler)} size="small" style={{marginBottom: '20px'}}>
                        {a.positions.map(p=>
                            <div style={{marginBottom: '5px'}}>
                                <BarChartOutlined onClick={_=>openModal(a.id, p)} style={{marginRight: '10px'}}/>
                                {p.goodName} {p.priceArrival} / {p.priceSell} = {p.percent}% 
                            </div>)}
                    </Card>)
                }
            </div>)}
    </div>
}

export default PercentArrivalProblems;