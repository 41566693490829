import { Button, DatePicker, Select, Space, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import $api from '../../http';
import NoteBookReport from './NoteBookReport';
import getPositions from './hooks/getPositions';
import { CalculatorFilled, InfoOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

function NoteBookView() {
  //const [shops, setShops] = useState([{value: 0, label: ''}]);
  const [shops, setShops] = useState([{id: 0, name: '', dbName: ''}]);
  const [selectShopId, setSelectShopId] = useState(null);
  const [positions, setPositions] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().add(-10, 'day'));
  const [stopDate, setStopDate] = useState(dayjs());
  const [report, setReport] = useState({shopId: null, positionId: null});

  /*
  const getPositions = useCallback(() => {
    const _getPositions = async () => {
      if(selectShopId===null || selectShopId===0)
        return;
      try{
        const startDateFormat = startDate.format('YYYY-MM-DD');
        const stopDateFormat = stopDate.format('YYYY-MM-DD');
        const result = await $api.get(`/notebook?start=${startDateFormat}&stop=${stopDateFormat}&shopid=${selectShopId}`);
        setPositions(result.data.map(x=>({...x, openReport: false}) ));
      }
      catch(e){
        console.error("Get positions error: ",e);
      }
    };
    _getPositions();
  }, [startDate, stopDate, selectShopId]);
  */
  useEffect(() => {
    const getShops = async () => {
      try{
        const result = await $api.get('/shops');
        setShops(result.data);
      }
      catch(e){
        console.error("Get positions error: ",e);
      }
    };
    getShops();
  }, []);

  useEffect(() => {
    getPositions(startDate, stopDate, selectShopId, setPositions);
  }, [startDate, stopDate, selectShopId]);

  const recalcDay = async (date) => {
    if(!window.confirm("Пересчитать?")) return;
    try{
      const result = await $api.post("/notebook/recalc", {date});
      if(result.status===200)
        getPositions();
    }
    catch(e){
      console.error("Recalc error", e);
    }
  }

  const recalcShop = async (id, shopId, date) => {
    if(!window.confirm("Пересчитать?")) return;
    setPositions(positions.map(x=>{
      if(x.id===id) return {...x, isProcessing: true};
      return {...x};
    }));
    try{
      const result = await $api.post("/notebook/recalc-shop", {date, shopId});
      if(result.status===200)
        getPositions(startDate, stopDate, selectShopId, setPositions);
    }
    catch(e){
      console.error("Recalc error", e);
    }
    setPositions(positions.map(x=>{
      if(x.id===id) return {...x, isProcessing: false};
      return {...x};
    }));
  }

  const openReport = (position) => setReport({shopId: selectShopId, positionId: position.id});

  return (
    <div>
      <NoteBookReport onClose={_=>setReport({shopId: null, positionId: null})} {...report}/>
      <RangePicker defaultValue={[startDate, stopDate]} onChange={(e, _) => { setStartDate(e[0]); setStopDate(e[1]); }} />
      <Select defaultValue={0} options={shops.map(x=> ({value: x.id, label: x.name}) )} onChange={e=>setSelectShopId(e)} style={{"width": "200px"}} placeholder="Магазин" />
      {
        (selectShopId===null || selectShopId===0) ? <></> :
          <a href={ "https://" + shops.find(x=>x.id===selectShopId).dbName + ".beermag31.ru"} target='_blank'>Открыть</a>
      }
      <br/>
      {
        (selectShopId===null || selectShopId===0) ?
          <div>Выберите магазин</div> :
          <div className='notebook'>
            <div className='table'>
              <div>Дата</div>
              <div>Инвент</div>
              <div>Приходы</div>
              <div>Переоценка</div>
              <div>Выплаты</div>
              <div>Списания</div>
              <div>Выручка</div>
              <div>Конец дня</div>
              <>
                {positions.map(x=><>
                    <div>
                      {dayjs(x.date).format('DD.MM.YY')}
                      <div>
                        <Space>
                          <Button onClick={_=>recalcShop(x.id, selectShopId, dayjs(x.date).format('DD.MM.YYYY'))} color='green' size='small'><CalculatorFilled /></Button>
                          {x.isProcessing && <Spin />}
                          <Button size='small'><InfoOutlined /></Button>
                        </Space>
                      </div>
                    </div>
                    <div>
                      {x.inventoryGoodSum>0 && <>
                        <div>Товар {x.inventoryGoodSum}</div>
                        <div>Наличные {x.inventoryCashSum}</div>
                        <div><button onClick={_=>{ openReport(x); }} >Открыть</button></div>
                      </>}
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '200px 70px 70px'}}>
                      {x.arrivals.map(a=><>
                        <div>{a.supplierName}</div>
                        <div className='sum'>{a.sum}</div>
                        <div className='sum'>{a.sumSell}</div>
                      </>)}
                    </div>
                    <div></div>
                    <div style={{display: 'grid', gridTemplateColumns: '200px 70px'}}>
                      {x.cashMoneyesOutcome.map(c=><><div>{c.note}</div><div className='sum'>{c.sum}</div></>)}
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '200px 70px'}}>
                      {x.writeofs.map(c=><><div>{c.note}</div><div>{c.sum}</div></>)}
                    </div>
                    <div>Безналичные {x.shiftElectronSum}<br/>Наличные {x.shiftCashSum}</div>
                    <div>
                      Сумма товара {x.endGoodSum}<br/>
                      Наличные {x.endCashSum}
                    </div>

                    <div></div>
                    <div></div>
                    <div><b>{x.arrivalsSum}</b></div>
                    <div><b>{x.revaluationSum}</b></div>
                    <div><b>{x.moneyOutcomeSum}</b></div>
                    <div><b>{x.writeofSum}</b></div>
                    <div><b>{x.shiftElectronSum + x.shiftCashSum}</b></div>
                    <div><b>{x.endGoodSum + x.endCashSum}</b></div>
                  </>)}
              </>
            </div>
          </div>
      }
    </div>
  );
}

export default NoteBookView;
