import { Button, List, Select, Space, Input, Modal } from "antd";
import { useState } from "react";
import $api, { GOODS_COMPARE_URL } from "../../../../http";

export default function EditGoodModal({good, onOk, onCancel}){
    const [name, setName] = useState(good.name);
    const [price, setPrice] = useState(`${good.price}`);

    const editHandler = async () => {
        try{
            if(price=='') return;
            const result = await $api.put(GOODS_COMPARE_URL + "/goods", {id: good.id, name, price:parseFloat(price)});
            onOk();
        }
        catch(e){
            console.error(e);
        }

    }

    return <Modal onOk={editHandler} onCancel={onCancel} open={true}>
        <div>Наименование: <Input value={name} onChange={e=>setName(e.target.value)} /></div>
        <div>Цена: <Input value={price} onChange={e=>setPrice(e.target.value)}/></div>
    </Modal>;
}